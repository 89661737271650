import { inject } from '@angular/core';
import { Router } from '@angular/router';

import { of, zip } from 'rxjs';
import { concatMap, catchError } from 'rxjs/operators';

import { UserService } from '@shared/services/user.service';

export const SelectedOrganizationGuard = () => {
  const router = inject(Router);
  return SelectedOrganizationGuardFn(router);
};

export const SelectedOrganizationGuardFn = (router: Router, userService = inject(UserService)) => {
  const isPlatformUser$ = userService.isPlatformUser();
  const userOrganization$ = userService.getUserOrganization().pipe(
    concatMap(organization => {
      return organization ? of(true) : router.navigate(['/select-organization']);
    })
  );

  // platform user does not need to check for user selected organization
  // users that only have one organizations does not need to select organization
  const userOrganizations$ = userService.getUserOrganizations().pipe(catchError(() => of([])));
  return zip(isPlatformUser$, userOrganizations$).pipe(
    concatMap(response => {
      const [isPlatformUser, userOrganizations] = response;
      if (isPlatformUser || userOrganizations.length < 2) {
        return userService.setUserLocalOrganization(userOrganizations[0]?.id);
      }
      return userOrganization$;
    })
  );
};
