import { Injectable } from '@angular/core';

import { EventSourcePolyfill } from 'event-source-polyfill';
import { Subject } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { INotification } from '@core/models/notification.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommunicationService {
  message$ = new Subject<INotification>();
  source: EventSourcePolyfill;

  constructor(private authService: AuthService) {}

  connect = (): void => {
    this.authService.getTokenSilently().subscribe(token => {
      const { __raw } = token;
      this.setListener(__raw);
    });
  };

  setListener = (jwtToken: string): void => {
    this.source = new EventSourcePolyfill(environment.WEBSOCKETEVENTSOURCE, {
      headers: {
        authorization: `Bearer ${jwtToken}`,
      },
    });
    this.source.addEventListener('message', this.sendMessage, false);
  };

  private sendMessage = webSocketResponse => {
    const message = JSON.parse(webSocketResponse.data);
    this.message$.next(message);
  };
}
