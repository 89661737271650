<section class="alerts">
  <div *ngFor="let alert of alerts" class="{{ cssClass(alert) }}">
    <section class="alert__body">
      <div class="alert__icon">
        <ng-container [ngSwitch]="alert.type">
          <ng-container *ngSwitchCase="alertType.Success">
            <ng-container *ngTemplateOutlet="alertSuccess"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="alertType.Warning">
            <ng-container *ngTemplateOutlet="alertWarning"></ng-container>
          </ng-container>
          <ng-container *ngSwitchCase="alertType.Error">
            <ng-container *ngTemplateOutlet="alertError"></ng-container>
          </ng-container>
        </ng-container>
      </div>
      <div class="alert__text">
        <h3 *ngIf="alertType.Error === alert.type">{{ alert?.title || 'Oops...something happened!' }}</h3>
        <h3 *ngIf="alertType.Warning === alert.type">{{ alert?.title || 'Warning, please check!' }}</h3>
        <h3 *ngIf="alertType.Success === alert.type">{{ alert?.title || 'Congratulations!' }}</h3>
        <span [innerHTML]="alert.message"></span>
      </div>
    </section>
    <a class="close" (click)="removeAlert(alert)">&times;</a>
  </div>
</section>

<ng-template #alertSuccess>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 533.8 510.6"
    style="enable-background: new 0 0 533.8 510.6"
    xml:space="preserve"
  >
    <style type="text/css">
      .st0 {
        fill: var(--primary-60);
      }
    </style>
    <g>
      <path
        class="st0"
        d="M500.8,19.1c-8.9-3.5-20.9,0.7-28.9,4.6C420.7,48.8,374.6,84,336.2,126.2c-37.3,41-67.2,89.1-87.3,140.8
      c-6.5,16.7-12,33.8-16.3,51.2c-29.7-25.5-60.5-49.8-92.1-72.9c-7.1-5.1-19.5-2.1-26.8,1c-3,1.3-18.5,9-11.6,14.1
      c43.1,31.4,84.4,65.2,123.9,101c4.9,4.4,16,2.4,21.4,0.8c3.5-1,6.9-2.3,10.1-3.9c2.2-1.1,7.6-4.5,8.1-7.5
      c8.1-53.5,26.7-105,53.9-151.8c13.3-22.8,28.8-44.3,46-64.4c8.8-10.2,18-20,27.6-29.4c8.7-8.5,18.3-16.9,29.1-25.6
      c11.4-9.1,23.2-17.6,35.4-25.5c0.3-0.2,2.8-1.8,3.5-2.2c1.7-1,3.4-2.1,5.1-3.1c3.2-1.9,6.4-3.8,9.7-5.6c6.7-3.8,13.6-7.3,20.5-10.8
      C501.4,30.1,509.3,22.5,500.8,19.1L500.8,19.1z"
      />
      <path
        class="st0"
        d="M233.5,474.5c-114.5,0-207.7-93.2-207.7-207.7S119,59.1,233.5,59.1c34.5,0,68.6,8.6,98.8,24.9
      c3.1,1.7,4.2,5.5,2.6,8.6s-5.5,4.2-8.6,2.6C298,79.9,266,71.8,233.6,71.8c-107.5,0-195,87.5-195,195s87.5,195,195,195
      s195-87.5,195-195c0-44.4-15.3-87.9-43.2-122.4c-2.2-2.7-1.8-6.7,1-8.9c2.7-2.2,6.7-1.8,8.9,1c29.6,36.7,46,83,46,130.3
      C441.2,381.3,348,474.5,233.5,474.5L233.5,474.5z"
      />
    </g></svg
></ng-template>
<ng-template #alertWarning>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 578 572.4"
    style="enable-background: new 0 0 578 572.4; transform: scale(2)"
    xml:space="preserve"
  >
    <style type="text/css">
      .st0 {
        fill: var(--primary-60);
      }
    </style>
    <path class="st0" d="M315.1,409.8c0,14.4-11.7,26.1-26.1,26.1s-26.1-11.7-26.1-26.1s11.7-26.1,26.1-26.1S315.1,395.4,315.1,409.8z" />
    <g transform="matrix(.65365 0 0 .65365 366.87 33.917)">
      <path
        class="st0"
        d="M-64.3,249.6c0,19.6-10.4,37.7-27.4,47.5l0,0c-17,9.8-37.9,9.8-54.8,0l0,0c-17-9.8-27.4-27.9-27.4-47.5v0
      c0-19.6,10.4-37.7,27.4-47.5l0,0c17-9.8,37.9-9.8,54.8,0l0,0C-74.8,211.9-64.3,230-64.3,249.6"
      />
      <path
        class="st0"
        d="M-97.1,488.9c0,7.9-4.2,15.3-11.1,19.3l0,0c-6.9,4-15.4,4-22.2,0l0,0c-6.9-4-11.1-11.3-11.1-19.3v0
      c0-7.9,4.2-15.3,11.1-19.3l0,0c6.9-4,15.4-4,22.2,0S-97.1,480.9-97.1,488.9"
      />
      <g transform="translate(544.69 -40.88)">
        <path
          class="st0"
          d="M-610.3,299.7c0,19.1-10.2,36.8-26.8,46.4l0,0l0,0l0,0c-16.6,9.6-37,9.6-53.6,0v0l0,0l0,0
        c-16.6-9.6-26.8-27.3-26.8-46.4v0c0-19.1,10.2-36.8,26.8-46.4v0l0,0l0,0c16.6-9.6,37-9.6,53.6,0l0,0c16.3,9.4,26.4,26.8,26.4,45.7
        C-610.5,299.1-610.3,299.4-610.3,299.7"
        />
        <path
          class="st0"
          d="M-611.6,308.9c0,18.7-10,36-26.2,45.3l0,0l0,0l0,0c-16.2,9.3-36.1,9.3-52.3,0l0,0l0,0l0,0
        c-16.2-9.3-26.2-26.6-26.2-45.3v0c0-18.7,10-36,26.2-45.3l0,0l0,0l0,0c16.2-9.3,36.1-9.3,52.3,0l0,0c15.7,9,25.3,25.7,25.3,43.8
        C-611.9,307.7-611.6,308.3-611.6,308.9"
        />
        <path
          class="st0"
          d="M-612.8,318.1c0,18.2-9.7,35.1-25.5,44.2l0,0l0,0l0,0c-15.8,9.1-35.3,9.1-51.1,0l0,0l0,0l0,0
        c-15.8-9.1-25.5-26-25.5-44.2v0c0-18.2,9.7-35.1,25.5-44.2l0,0l0,0l0,0c15.8-9.1,35.3-9.1,51.1,0l0,0c15,8.7,24.2,24.7,24.2,42
        C-613.3,316.3-612.8,317.2-612.8,318.1"
        />
        <path
          class="st0"
          d="M-614.1,327.3c0,17.8-9.5,34.2-24.9,43.1l0,0l0,0l0,0c-15.4,8.9-34.4,8.9-49.8,0l0,0l0,0l0,0
        c-15.4-8.9-24.9-25.3-24.9-43.1v0c0-17.8,9.5-34.2,24.9-43.1l0,0l0,0l0,0c15.4-8.9,34.4-8.9,49.8,0l0,0
        c14.4,8.3,23.2,23.6,23.2,40.2C-614.7,324.9-614.1,326.1-614.1,327.3"
        />
        <path
          class="st0"
          d="M-615.3,336.5c0,17.3-9.3,33.4-24.3,42.1l0,0l0,0l0,0c-15,8.7-33.5,8.7-48.6,0l0,0l0,0l0,0
        c-15-8.7-24.3-24.7-24.3-42.1v0c0-17.3,9.3-33.4,24.3-42.1l0,0l0,0l0,0c15-8.7,33.5-8.7,48.6,0l0,0c13.7,7.9,22.1,22.5,22.1,38.4
        C-616.2,333.6-615.3,335-615.3,336.5"
        />
        <path
          class="st0"
          d="M-616.6,345.7c0,16.9-9,32.5-23.7,41l0,0l0,0l0,0c-14.6,8.4-32.7,8.4-47.3,0l0,0l0,0l0,0
        c-14.6-8.5-23.7-24.1-23.7-41v0c0-16.9,9-32.5,23.7-41l0,0l0,0l0,0c14.6-8.4,32.7-8.4,47.3,0l0,0c13,7.5,21.1,21.5,21.1,36.5
        C-617.6,342.2-616.6,343.9-616.6,345.7"
        />
        <path
          class="st0"
          d="M-617.9,354.9c0,16.5-8.8,31.7-23,39.9l0,0l0,0l0,0c-14.2,8.2-31.8,8.2-46.1,0l0,0l0,0l0,0
        c-14.2-8.2-23-23.4-23-39.9v0c0-16.5,8.8-31.7,23-39.9l0,0l0,0l0,0c14.2-8.2,31.8-8.2,46.1,0l0,0c12.4,7.2,20,20.4,20,34.7
        C-619,350.8-617.9,352.8-617.9,354.9"
        />
        <path
          class="st0"
          d="M-619.1,364.1c0,16-8.5,30.8-22.4,38.8l0,0l0,0l0,0c-13.9,8-30.9,8-44.8,0l0,0l0,0l0,0
        c-13.9-8-22.4-22.8-22.4-38.8v0c0-16,8.5-30.8,22.4-38.8l0,0l0,0l0,0c13.9-8,30.9-8,44.8,0l0,0c11.7,6.8,19,19.3,19,32.9
        C-620.4,359.4-619.1,361.7-619.1,364.1"
        />
        <path
          class="st0"
          d="M-620.4,373.3c0,15.6-8.3,29.9-21.8,37.7l0,0l0,0l0,0c-13.5,7.8-30.1,7.8-43.5,0l0,0l0,0l0,0
        c-13.5-7.8-21.8-22.2-21.8-37.7v0c0-15.6,8.3-29.9,21.8-37.7l0,0l0,0l0,0c13.5-7.8,30.1-7.8,43.5,0l0,0
        c11.1,6.4,17.9,18.2,17.9,31C-621.8,368-620.4,370.5-620.4,373.3"
        />
        <path
          class="st0"
          d="M-621.6,382.5c0,15.1-8.1,29.1-21.1,36.6l0,0h0l0,0c-13.1,7.6-29.2,7.6-42.3,0l0,0v0l0,0
        c-13.1-7.6-21.1-21.5-21.1-36.6v0c0-15.1,8.1-29.1,21.1-36.6l0,0h0l0,0c13.1-7.6,29.2-7.6,42.3,0l0,0c10.4,6,16.9,17.2,16.9,29.2
        C-623.3,376.6-621.6,379.5-621.6,382.5"
        />
        <path
          class="st0"
          d="M-622.9,391.7c0,14.7-7.8,28.2-20.5,35.5l0,0h0l0,0c-12.7,7.3-28.3,7.3-41,0l0,0l0,0l0,0
        c-12.7-7.3-20.5-20.9-20.5-35.5v0c0-14.7,7.8-28.2,20.5-35.5l0,0l0,0l0,0c12.7-7.3,28.3-7.3,41,0l0,0c9.8,5.7,15.8,16.1,15.8,27.4
        C-624.7,385.2-622.9,388.3-622.9,391.7"
        />
        <path
          class="st0"
          d="M-624.2,400.9c0,14.2-7.6,27.3-19.9,34.5l0,0h0l0,0c-12.3,7.1-27.5,7.1-39.8,0l0,0v0l0,0
        c-12.3-7.1-19.9-20.2-19.9-34.5v0c0-14.2,7.6-27.3,19.9-34.5l0,0l0,0l0,0c12.3-7.1,27.5-7.1,39.8,0l0,0c9.1,5.3,14.8,15,14.8,25.6
        C-626.1,393.9-624.2,397.2-624.2,400.9"
        />
        <path
          class="st0"
          d="M-625.4,410.1c0,13.8-7.3,26.5-19.3,33.4l0,0l0,0l0,0c-11.9,6.9-26.6,6.9-38.5,0l0,0v0l0,0
        c-11.9-6.9-19.3-19.6-19.3-33.4v0c0-13.8,7.3-26.5,19.3-33.4l0,0v0l0,0c11.9-6.9,26.6-6.9,38.5,0l0,0c8.5,4.9,13.7,13.9,13.7,23.7
        C-627.5,402.5-625.4,406.1-625.4,410.1"
        />
        <path
          class="st0"
          d="M-626.7,419.3c0,13.3-7.1,25.6-18.6,32.3l0,0v0l0,0c-11.5,6.7-25.7,6.7-37.3,0l0,0v0l0,0
        c-11.5-6.7-18.6-19-18.6-32.3v0c0-13.3,7.1-25.6,18.6-32.3l0,0v0l0,0c11.5-6.7,25.7-6.7,37.3,0l0,0c7.8,4.5,12.7,12.9,12.7,21.9
        C-629,411.1-626.7,415-626.7,419.3"
        />
        <path
          class="st0"
          d="M-627.9,428.5c0,12.9-6.9,24.8-18,31.2l0,0v0l0,0c-11.1,6.4-24.9,6.4-36,0l0,0v0l0,0
        c-11.1-6.4-18-18.3-18-31.2v0c0-12.9,6.9-24.8,18-31.2l0,0l0,0l0,0c11.1-6.4,24.9-6.4,36,0l0,0c7.2,4.1,11.6,11.8,11.6,20.1
        C-630.4,419.7-627.9,423.9-627.9,428.5"
        />
        <path
          class="st0"
          d="M-629.2,437.7c0,12.4-6.6,23.9-17.4,30.1l0,0v0l0,0c-10.8,6.2-24,6.2-34.8,0l0,0v0l0,0
        c-10.8-6.2-17.4-17.7-17.4-30.1v0c0-12.4,6.6-23.9,17.4-30.1l0,0v0l0,0c10.8-6.2,24-6.2,34.8,0l0,0c6.5,3.8,10.5,10.7,10.5,18.3
        C-631.8,428.3-629.2,432.8-629.2,437.7"
        />
        <path
          class="st0"
          d="M-630.5,446.9c0,12-6.4,23-16.8,29l0,0l0,0l0,0c-10.4,6-23.1,6-33.5,0l0,0v0l0,0c-10.4-6-16.8-17.1-16.8-29v0
        c0-12,6.4-23,16.8-29l0,0l0,0l0,0c10.4-6,23.1-6,33.5,0l0,0c5.9,3.4,9.5,9.7,9.5,16.4C-633.2,436.9-630.5,441.7-630.5,446.9"
        />
        <path
          class="st0"
          d="M-631.7,456.1c0,11.5-6.1,22.2-16.1,27.9l0,0l0,0l0,0c-10,5.8-22.3,5.8-32.3,0l0,0l0,0l0,0
        c-10-5.8-16.1-16.4-16.1-27.9v0c0-11.5,6.2-22.2,16.1-27.9l0,0l0,0l0,0c10-5.8,22.3-5.8,32.3,0l0,0c5.2,3,8.4,8.6,8.4,14.6
        C-634.6,445.6-631.7,450.6-631.7,456.1"
        />
        <path
          class="st0"
          d="M-633,465.3c0,11.1-5.9,21.3-15.5,26.9l0,0l0,0l0,0c-9.6,5.5-21.4,5.5-31,0l0,0v0l0,0
        c-9.6-5.5-15.5-15.8-15.5-26.9v0c0-11.1,5.9-21.3,15.5-26.9l0,0l0,0l0,0c9.6-5.5,21.4-5.5,31,0l0,0c4.6,2.6,7.4,7.5,7.4,12.8
        C-636.1,454.2-633,459.5-633,465.3"
        />
        <path
          class="st0"
          d="M-634.2,474.5c0,10.6-5.7,20.5-14.9,25.8l0,0l0,0l0,0c-9.2,5.3-20.6,5.3-29.8,0l0,0l0,0l0,0
        c-9.2-5.3-14.9-15.1-14.9-25.8v0c0-10.6,5.7-20.5,14.9-25.8l0,0l0,0l0,0c9.2-5.3,20.6-5.3,29.8,0l0,0c3.9,2.3,6.3,6.4,6.3,11
        C-637.5,462.8-634.2,468.4-634.2,474.5"
        />
        <path
          class="st0"
          d="M-635.5,483.8c0,10.2-5.4,19.6-14.3,24.7l0,0l0,0l0,0c-8.8,5.1-19.7,5.1-28.5,0l0,0l0,0l0,0
        c-8.8-5.1-14.3-14.5-14.3-24.7v0c0-10.2,5.4-19.6,14.3-24.7l0,0l0,0l0,0c8.8-5.1,19.7-5.1,28.5,0l0,0c3.3,1.9,5.3,5.4,5.3,9.1
        C-638.9,471.4-635.5,477.3-635.5,483.8"
        />
        <path
          class="st0"
          d="M-636.7,493c0,9.7-5.2,18.7-13.6,23.6l0,0l0,0l0,0c-8.4,4.9-18.8,4.9-27.3,0l0,0l0,0l0,0
        c-8.4-4.9-13.6-13.9-13.6-23.6v0c0-9.7,5.2-18.7,13.6-23.6l0,0l0,0l0,0c8.4-4.9,18.8-4.9,27.3,0l0,0c2.6,1.5,4.2,4.3,4.2,7.3
        C-640.3,480-636.7,486.2-636.7,493"
        />
        <path
          class="st0"
          d="M-638,502.1c0,9.3-5,17.9-13,22.5l0,0l0,0l0,0c-8,4.6-18,4.6-26,0l0,0l0,0l0,0c-8-4.6-13-13.2-13-22.5v0
        c0-9.3,5-17.9,13-22.5l0,0l0,0l0,0c8-4.6,18-4.6,26,0l0,0c2,1.1,3.2,3.2,3.2,5.5C-641.7,488.6-638,495.1-638,502.1"
        />
        <path
          class="st0"
          d="M-639.3,511.4c0,8.8-4.7,17-12.4,21.4l0,0l0,0c-7.7,4.4-17.1,4.4-24.7,0l0,0l0,0l0,0
        c-7.7-4.4-12.4-12.6-12.4-21.4v0c0-8.8,4.7-17,12.4-21.4l0,0l0,0l0,0c7.7-4.4,17.1-4.4,24.7,0l0,0c1.3,0.8,2.1,2.1,2.1,3.7
        C-643.2,497.3-639.3,504-639.3,511.4"
        />
        <path
          class="st0"
          d="M-640.5,520.6c0,8.4-4.5,16.1-11.7,20.3l0,0h0c-7.3,4.2-16.2,4.2-23.5,0l0,0l0,0h0
        c-7.3-4.2-11.7-12-11.7-20.3v0c0-8.4,4.5-16.1,11.7-20.3l0,0l0,0c7.3-4.2,16.2-4.2,23.5,0l0,0c0.7,0.4,1.1,1.1,1.1,1.8
        C-644.6,505.9-640.5,512.9-640.5,520.6"
        />
      </g>
    </g>
  </svg>
</ng-template>
<ng-template #alertError>
  <svg
    version="1.1"
    id="svg4216"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 500 500"
    style="enable-background: new 0 0 500 500"
    xml:space="preserve"
  >
    <style type="text/css">
      .st0 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #ffffff;
        stroke: var(--primary-60);
        stroke-width: 2.9151;
      }
      .st1 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: var(--primary-60);
      }
      .st2 {
        fill-rule: evenodd;
        clip-rule: evenodd;
        fill: #ffffff;
      }
      .st3 {
        fill: var(--primary-60);
      }
    </style>
    <g id="layer1" transform="translate(0,-552.36216)">
      <path
        id="rect4770"
        class="st0"
        d="M6,791.5l233.2-233.2c6-6,15.7-6,21.7,0L494,791.5c6,6,6,15.7,0,21.7l-233.2,233.2
		c-6,6-15.7,6-21.7,0L6,813.2C0,807.2,0,797.5,6,791.5z"
      />
      <path
        id="rect4770-7"
        class="st1"
        d="M24.2,792.3L240,576.5c5.5-5.5,14.5-5.5,20.1,0l215.8,215.8c5.5,5.5,5.5,14.5,0,20.1
		L260,1028.2c-5.5,5.5-14.5,5.5-20.1,0L24.2,812.4C18.6,806.9,18.6,797.9,24.2,792.3z"
      />
      <path
        id="rect4770-7-2"
        class="st2"
        d="M34,792.8l206.4-206.4c5.3-5.3,13.9-5.3,19.2,0L466,792.8c5.3,5.3,5.3,13.9,0,19.2
		l-206.4,206.4c-5.3,5.3-13.9,5.3-19.2,0L34,812C28.7,806.7,28.7,798.1,34,792.8z"
      />
    </g>
    <g id="layer4">
      <g id="text4867">
        <path
          id="path4875"
          class="st3"
          d="M164.2,241.6c0,6.6-0.8,12.3-2.3,17c-1.5,4.8-3.6,8.7-6.3,11.8c-2.7,3.1-5.8,5.3-9.4,6.8
			c-3.6,1.5-7.4,2.2-11.6,2.2c-9.6,0-16.7-3.2-21.5-9.5c-4.8-6.4-7.2-15.6-7.2-27.7c0-6.6,0.8-12.3,2.3-17c1.6-4.8,3.7-8.7,6.3-11.8
			c2.7-3.1,5.8-5.3,9.4-6.8c3.6-1.5,7.5-2.2,11.6-2.2c9.6,0,16.7,3.2,21.5,9.5C161.8,220.2,164.2,229.5,164.2,241.6L164.2,241.6z
			 M149.8,242.3c0-8.6-1.1-15-3.4-19.2s-6.1-6.3-11.3-6.3c-2.6,0-4.9,0.6-6.7,1.7c-1.9,1.1-3.4,2.7-4.6,4.8
			c-1.2,2.1-2.1,4.7-2.6,7.8c-0.6,3.1-0.8,6.6-0.8,10.6c0,8.6,1.1,15,3.4,19.2c2.3,4.2,6.1,6.3,11.3,6.3c2.6,0,4.9-0.5,6.7-1.6
			c1.9-1.1,3.4-2.7,4.6-4.8c1.2-2.1,2.1-4.7,2.6-7.8C149.5,249.8,149.8,246.3,149.8,242.3L149.8,242.3z"
        />
        <path
          id="path4877"
          class="st3"
          d="M224.9,249.6c0,4.4-0.6,8.5-1.9,12.2c-1.3,3.7-3.1,6.8-5.4,9.4c-2.4,2.6-5.3,4.7-8.7,6.1
			c-3.4,1.4-7.4,2.2-11.8,2.2c-4.2,0-7.9-0.6-11.2-1.8c-3.3-1.2-6.1-3-8.4-5.4c-2.3-2.4-4.1-5.5-5.3-9.1c-1.2-3.7-1.8-7.9-1.8-12.8
			c0-4.5,0.6-8.6,1.9-12.2c1.3-3.7,3.1-6.8,5.5-9.4c2.4-2.6,5.3-4.6,8.8-6c3.4-1.4,7.3-2.2,11.7-2.2c4.2,0,8,0.6,11.3,1.9
			c3.3,1.2,6.1,3.1,8.4,5.5c2.3,2.4,4,5.5,5.2,9.1C224.3,240.7,224.9,244.8,224.9,249.6L224.9,249.6z M210.8,249.9
			c0-5.8-1.1-10.2-3.3-13.2c-2.2-3-5.4-4.4-9.7-4.4c-2.4,0-4.4,0.5-6.1,1.4c-1.7,0.9-3,2.2-4.1,3.8c-1.1,1.6-1.9,3.5-2.4,5.6
			c-0.5,2.1-0.7,4.4-0.7,6.9c0,5.9,1.2,10.3,3.6,13.3c2.4,3,5.6,4.4,9.7,4.4c2.3,0,4.2-0.4,5.9-1.3c1.7-0.9,3-2.2,4.1-3.8
			c1-1.6,1.8-3.5,2.3-5.7C210.5,254.8,210.8,252.4,210.8,249.9L210.8,249.9z"
        />
        <path
          id="path4879"
          class="st3"
          d="M287.5,249c0,5.2-0.7,9.7-2.2,13.4c-1.4,3.8-3.5,6.9-6.1,9.4c-2.6,2.5-5.7,4.3-9.2,5.6
			c-3.6,1.2-7.4,1.8-11.6,1.8c-1.4,0-2.8-0.1-4.2-0.3s-2.7-0.4-3.8-0.7v22h-13.5v-78.5h11.7l0.3,8.3c1.1-1.4,2.3-2.8,3.6-3.9
			c1.3-1.2,2.6-2.2,4.1-3c1.4-0.9,3-1.5,4.7-1.9s3.5-0.7,5.6-0.7c3.3,0,6.3,0.7,8.9,2c2.6,1.3,4.8,3.2,6.5,5.7
			c1.8,2.4,3.1,5.4,4.1,8.9C287,240.7,287.5,244.6,287.5,249L287.5,249z M273.3,249.6c0-3.1-0.2-5.8-0.7-7.9c-0.4-2.2-1.1-4-1.9-5.4
			c-0.8-1.4-1.8-2.4-3.1-3.1c-1.2-0.7-2.6-1-4.1-1c-2.3,0-4.5,0.9-6.6,2.8c-2.1,1.9-4.3,4.4-6.6,7.6v24.7c1.1,0.4,2.4,0.7,3.9,1
			c1.6,0.3,3.2,0.4,4.8,0.4c2.1,0,4.1-0.4,5.8-1.3c1.8-0.9,3.3-2.1,4.5-3.8c1.3-1.6,2.2-3.6,2.9-5.9
			C272.9,255.1,273.3,252.5,273.3,249.6L273.3,249.6z"
        />
        <path
          id="path4881"
          class="st3"
          d="M345.9,261.6c0,3.2-0.7,6-2.2,8.3c-1.4,2.3-3.3,4.1-5.7,5.6c-2.3,1.4-5,2.4-8,3.1
			c-3,0.7-6,1-9.1,1c-4.1,0-7.9-0.2-11.2-0.6c-3.3-0.4-6.5-0.9-9.4-1.7V265c3.5,1.4,6.9,2.5,10.4,3.2c3.5,0.6,6.8,0.9,9.8,0.9
			c3.6,0,6.2-0.6,7.9-1.7c1.8-1.1,2.7-2.6,2.7-4.4c0-0.9-0.2-1.6-0.6-2.3c-0.4-0.7-1.1-1.4-2.1-2c-1-0.7-2.4-1.3-4.3-2
			c-1.9-0.7-4.3-1.5-7.3-2.3c-2.8-0.8-5.2-1.6-7.3-2.6c-2.1-1-3.8-2.2-5.2-3.5c-1.4-1.3-2.4-2.9-3.1-4.6c-0.7-1.8-1-3.9-1-6.2
			c0-2.3,0.5-4.5,1.6-6.5c1-2,2.6-3.8,4.6-5.3c2.1-1.6,4.6-2.8,7.7-3.7s6.7-1.3,10.8-1.3c3.6,0,6.7,0.2,9.4,0.6
			c2.7,0.4,5.2,0.8,7.3,1.2v11.1c-3.2-1-6.3-1.8-9.1-2.2c-2.8-0.4-5.6-0.7-8.4-0.7s-5,0.5-6.7,1.5c-1.7,1-2.5,2.4-2.5,4.2
			c0,0.9,0.2,1.6,0.5,2.3s1,1.3,1.9,1.9c1,0.6,2.4,1.3,4.1,2c1.8,0.7,4.1,1.4,7,2.2c3.3,0.9,6,1.9,8.2,3c2.2,1,4,2.2,5.3,3.6
			c1.4,1.3,2.4,2.9,2.9,4.6C345.6,257.5,345.9,259.4,345.9,261.6L345.9,261.6z"
        />
        <path
          id="path4883"
          class="st3"
          d="M391.4,255.7h-11.6l-2.1-55.9h15.7L391.4,255.7L391.4,255.7z M385.6,262.2
			c1.2,0,2.3,0.2,3.3,0.7c1.1,0.4,2,1.1,2.8,1.8c0.8,0.8,1.4,1.7,1.8,2.8c0.5,1,0.7,2.2,0.7,3.4c0,1.2-0.2,2.4-0.7,3.4
			c-0.4,1-1.1,1.9-1.8,2.7c-0.8,0.8-1.7,1.4-2.8,1.8c-1,0.4-2.1,0.7-3.3,0.7s-2.3-0.2-3.4-0.7c-1-0.4-1.9-1.1-2.7-1.8
			c-0.8-0.8-1.4-1.7-1.8-2.7c-0.4-1.1-0.7-2.2-0.7-3.4s0.2-2.4,0.7-3.4c0.4-1.1,1.1-2,1.8-2.8c0.8-0.8,1.7-1.4,2.7-1.8
			C383.2,262.4,384.4,262.2,385.6,262.2z"
        />
      </g>
    </g>
  </svg>
</ng-template>
