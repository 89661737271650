import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import {
  faHome,
  faUsers,
  faChartSimple,
  faQuestionCircle,
  faFileInvoiceDollar,
  faList,
  faPlus,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import { map, skipWhile } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { IUser } from '@core/models/user.model';
import { UserService } from '@shared/services/user.service';
import { NAMESPACE } from 'src/app/app.constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  faQuestionCircle = faQuestionCircle;
  faHome = faHome;
  faFileInvoiceDollar = faFileInvoiceDollar;
  faUsers = faUsers;
  faCog = faCog;
  faChartSimple = faChartSimple;
  faList = faList;
  faPlus = faPlus;

  isPlatform$: Observable<boolean>;

  constructor(protected authService: AuthService, protected userService: UserService) {}

  ngOnInit(): void {
    this.isPlatform$ = this.userService.isPlatformUser();
  }

  get isAdmin$(): Observable<boolean> {
    return this.authService.userProfile$.pipe(
      skipWhile(user => !user),
      map((user: IUser) => {
        return user[NAMESPACE].admin;
      })
    );
  }

  get isNonViewer$(): Observable<boolean> {
    return this.userService.isNonViewer();
  }
}
