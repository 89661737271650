import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable, zip, Subscription, combineLatest } from 'rxjs';
import { map, tap, take } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';
import { INotification } from '@core/models/notification.model';
import { NotificationType } from '@core/models/notification-type';
import { CommunicationService } from '@core/services/communication.service';
import { Status } from '@core/models/status';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RequestService implements OnDestroy {
  private pendingRequestsSource = new BehaviorSubject<INotification[]>([]);
  pendingRequests$ = this.pendingRequestsSource.asObservable();
  private requestsSource = new BehaviorSubject<INotification[]>([]);
  requests$ = this.requestsSource.asObservable();
  private requestsCountSource = new BehaviorSubject<{ [key: string]: number }>({ requests: 0, pendingRequests: 0, total: 0 });
  requestsCount$ = this.requestsCountSource.asObservable();
  subscriptions = new Subscription();
  private myRequests: INotification[] = [];
  private myPendingRequests: INotification[] = [];
  private removalActions: Status[] = [Status.CLOSED, Status.DELETED];

  constructor(private authService: AuthService, private commService: CommunicationService, private http: HttpClient) {
    this.init();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  addRequest = (actAs: string, notification: INotification): void => {
    const { userFrom, userTo, status, type } = notification;
    const subscriberPolicyUpdateRequest = this.subscriberPolicyUpdateRequest(notification);
    if (
      type !== NotificationType.POLICY_UNSUBSCRIBE &&
      !subscriberPolicyUpdateRequest &&
      actAs.includes(userTo) &&
      status.toLowerCase() === Status.PENDING.toLowerCase()
    ) {
      this.setPendingRequests([notification, ...this.myPendingRequests]);
    }
    if (!(NotificationType.POLICY_DELIST === type && actAs.includes(userFrom)) && actAs.includes(userFrom) && status.toLowerCase()) {
      this.setRequests([notification, ...this.myRequests]);
    }
  };

  clearAllRequests = (): Observable<any> => this.http.delete(environment.CLEARNOTIFICATIONS, {}).pipe(tap(this.filterNotPendingRequests));

  closeRequest = (id: string): Observable<any> => {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    const url = environment.CLOSE.replace('{notificationId}', id);
    return this.http.delete<any>(url, { headers, responseType: 'text' } as any).pipe(tap(() => this.deleteRequest(id)));
  };

  deleteRequest = (requestId: string): void => {
    const newRequests = this.myRequests.filter(request => request.id !== requestId);
    const newPendingRequests = this.myPendingRequests.filter(request => request.id !== requestId);
    this.setRequests(newRequests);
    this.setPendingRequests(newPendingRequests);
  };

  deleteFromResourceId = (resourceId: string): void => {
    const newRequests = this.myRequests.filter(request => request.resourceId !== resourceId || request.status === Status.DELETED);
    const newPendingRequests = this.myPendingRequests.filter(request => request.resourceId !== resourceId);
    this.setRequests(newRequests);
    this.setPendingRequests(newPendingRequests);
  };

  emitRequestsCount = (): void => {
    this.requestsCountSource.next({
      requests: this.myRequests.length,
      pendingRequests: this.myPendingRequests.length,
      total: this.myRequests.length + this.myPendingRequests.length,
    });
  };

  filterNotPendingRequests = (): void => {
    const requests = this.myRequests.filter(({ status }) => status.toLowerCase() === Status.PENDING.toLowerCase());
    this.setRequests(requests);
  };

  getMyRequests = (): Observable<INotification[]> => this.http.get<INotification[]>(environment.MYREQUESTS);

  getMyPendingRequests = (): Observable<INotification[]> => this.http.get<INotification[]>(environment.MYPENDINGREQUESTS);

  getRequests(): void {
    const requests = this.getMyRequests();
    const pendingRequests = this.getMyPendingRequests();
    this.subscriptions.add(
      zip(requests, pendingRequests).subscribe(([myRequests, myPendingRequests]) => {
        this.setRequests(myRequests);
        this.setPendingRequests(myPendingRequests);
      })
    );
  }

  init(): void {
    const newMessage$ = this.commService.message$;
    const user$ = this.authService.userActAs$.pipe(
      take(1),
      map(([actAs]) => actAs)
    );

    this.subscriptions.add(
      combineLatest([user$, newMessage$]).subscribe(([actAs, notification]: [string, INotification]) => {
        const { id, resourceId, status } = notification;
        if (this.removalActions.find(action => action.toLowerCase() === status.toLowerCase())) {
          if (status === Status.DELETED) {
            this.deleteFromResourceId(resourceId);
            this.addRequest(actAs, notification);
          } else {
            this.deleteRequest(id);
          }
        } else {
          this.addRequest(actAs, notification);
        }
      })
    );
  }

  setPendingRequests = (requests: INotification[]): void => {
    this.myPendingRequests = requests;
    this.pendingRequestsSource.next(JSON.parse(JSON.stringify(requests)));
    this.emitRequestsCount();
  };

  setRequests = (requests: INotification[]): void => {
    this.myRequests = requests;
    this.requestsSource.next(JSON.parse(JSON.stringify(requests)));
    this.emitRequestsCount();
  };

  subscriberPolicyUpdateRequest = (notification: INotification): boolean => {
    const { userFrom, userTo, type } = notification;
    return userFrom === userTo && type === NotificationType.EDIT_POLICY_CHANGE;
  };
}
