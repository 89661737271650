import { FormControl } from '@angular/forms';

import { ICustomStyles } from './custom-styles.model';
import { IBackgroundGroup, IStylesGroup } from './custom-styles-form.model';

export const defaultUIStyles = (): ICustomStyles => {
  return {
    backgroundCoverColor: '#ffffff',
    backgroundCoverImage: null,
    backgroundCoverImageMode: null,
    backgroundColor: null,
    backgroundImage: null,
    backgroundImageMode: null,
    strokeColor: '#E8E8EB',
    fontPrimaryColor: '#171a37',
    fontSecondaryColor: '#747687',
    actionFillColor: '#850599',
    actionFontColor: '#ffffff',
    font: null,
  };
};

export const defaultAltUIStyles = (): ICustomStyles => {
  return {
    backgroundCoverColor: '#ffffff',
    backgroundCoverImage: null,
    backgroundCoverImageMode: null,
    fontPrimaryColor: '#171a37',
    fontSecondaryColor: '#747687',
    font: null,
  };
};

export const getRegularStylesForm = (styles: ICustomStyles): IStylesGroup => {
  return {
    backgroundCover: new FormControl({
      color: styles.backgroundCoverColor,
      image: styles.backgroundCoverImage,
      imageMode: styles.backgroundCoverImageMode,
    }),
    strokeColor: new FormControl(styles.strokeColor),
    fontPrimaryColor: new FormControl(styles.fontPrimaryColor),
    fontSecondaryColor: new FormControl(styles.fontSecondaryColor),
    actionFillColor: new FormControl(styles.actionFillColor),
    actionFontColor: new FormControl(styles.actionFontColor),
    font: new FormControl(styles.font, { nonNullable: false }),
  };
};

export const getReducedStylesForm = (styles: ICustomStyles): IStylesGroup => {
  return {
    backgroundCover: new FormControl({
      color: styles.backgroundCoverColor,
      image: styles.backgroundCoverImage,
      imageMode: styles.backgroundCoverImageMode,
    }),
    fontPrimaryColor: new FormControl(styles.fontPrimaryColor),
    fontSecondaryColor: new FormControl(styles.fontSecondaryColor),
    font: new FormControl(styles.font, { nonNullable: false }),
  };
};

export const getExtendedStylesForm = (styles: ICustomStyles): IStylesGroup => {
  return {
    ...getRegularStylesForm(styles),
    ...getDifferentBGStylesForm(styles),
  };
};

interface IDifferentBGFields {
  background?: FormControl<IBackgroundGroup>;
  differentBackground: FormControl<boolean>;
}

export const getDifferentBGStylesForm = (styles: ICustomStyles): IDifferentBGFields => {
  const differentBackground = Boolean(styles.backgroundColor || styles.backgroundImage);
  return differentBackground
    ? {
        background: new FormControl({
          color: styles.backgroundColor,
          image: styles.backgroundImage,
          imageMode: styles.backgroundImageMode,
        }),
        differentBackground: new FormControl(differentBackground),
      }
    : {
        differentBackground: new FormControl(differentBackground),
      };
};

export const emptyStyles = (styles: ICustomStyles): boolean => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { color, ...rest } = styles;
  const empty = Object.keys(rest).every(key => {
    return styles[key] === '' || styles[key] === null || styles[key] === undefined;
  });
  return empty;
};

export const usesAltCoverStyles = (styles: ICustomStyles): boolean => {
  const { backgroundColor, backgroundImage, backgroundImageMode } = styles;
  return !!(backgroundImage || backgroundColor || backgroundImageMode);
};
