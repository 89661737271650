import { ModalComponent } from '@shared/components/modal/modal.component';
import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ICatalogPolicy } from '../../../../catalog/catalogPolicy.model';

@Component({
  selector: 'app-unsubscribe-policy-modal',
  templateUrl: './unsubscribe-policy-modal.component.html',
})
export class UnsubscribePolicyModalComponent extends ModalComponent<ICatalogPolicy> {
  @Input() policy: ICatalogPolicy;

  constructor(public ngbActiveModal: NgbActiveModal) {
    super(ngbActiveModal);
  }

  unsubscribe = () => {
    this.mainClick.emit(this.policy);
  };
}
