import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { MatchmediaService } from '@core/services/matchmedia.service';
import { RequestService } from '@core/services/request.service';
import { UserService } from '@shared/services/user.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private isNavMenuVisible = new BehaviorSubject<boolean>(false);
  isNavMenuVisible$ = this.isNavMenuVisible.asObservable();
  private accountClicked = new Subject<void>();
  accountClicked$ = this.accountClicked.asObservable();
  private notificationClicked = new Subject<void>();
  notificationClicked$ = this.notificationClicked.asObservable();

  constructor(
    private authService: AuthService,
    private matchmediaService: MatchmediaService,
    private userService: UserService,
    private requestService: RequestService
  ) {}

  get isPlatformUser$() {
    return this.userService.isPlatformUser();
  }

  get mediaChange$() {
    return this.matchmediaService.mediaChange$;
  }

  get requestsCount$() {
    return this.requestService.requestsCount$;
  }

  get userOrganization$() {
    return this.userService.getUserOrganization();
  }

  get userOrganizations$() {
    return this.userService.getUserOrganizationOptions();
  }

  get userProfile$() {
    return this.authService.userProfile$;
  }

  logout(): void {
    const { AUTHCONF } = environment;
    this.authService.logout({ client_id: AUTHCONF.clientId, returnTo: AUTHCONF.returnTo });
  }

  openMyAccount = (): void => this.toggleAccountModal();

  openNotifications = () => this.notificationClicked.next();

  setUserOrganization = (organizationId: string): Observable<string> => this.userService.setUserLocalOrganization(organizationId);

  toggleAccountModal = (): void => this.accountClicked.next();
}
