import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ModalComponent } from '@shared/components/modal/modal.component';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent extends ModalComponent<void> {
  @Input() labels = {
    title: '',
    subtitle: '',
    description: '',
    confirm: 'Confirm',
    cancel: 'Cancel',
  };

  constructor(public activeModal: NgbActiveModal) {
    super(activeModal);
  }

  confirm = (): void => {
    this.mainClick.emit();
  };
}
