import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, Subject, iif, of, zip } from 'rxjs';
import { catchError, concatMap, map, mergeMap, shareReplay, skipWhile, tap } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';
import { IOrganization } from '@core/models/organization.model';
import { IParticipantType } from '@core/models/participant-type.model';
import { IUserAccount } from '@core/models/user-account.model';
import { PlatformService } from '@core/services/platform.service';
import { environment } from '../../../environments/environment';
import { ICustomFormOption } from '@shared/features/custom-forms/custom-form-option.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private OCLabel = 'OCSelectedUserOrganization';
  private user$: Observable<IUserAccount>;
  private userId$: Observable<string>;
  private userOrganizations$: Observable<IOrganization[]>;
  private currentUser$;
  private userOrganizationChange = new Subject<void>();
  public userOrganizationChange$ = this.userOrganizationChange.asObservable();

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private platformService: PlatformService,
    private window: Window
  ) {}

  get defaultPlatformUser$(): Observable<IUserAccount> {
    return of({
      admin: true,
      group: null,
      email: environment.adminEmail,
      entitlements: null,
      id: '',
      imageProfile: '',
      imageProfileContentType: '',
      invitationToken: '',
      invitedBy: '',
      invitedOn: '',
      legalAgreement: false,
      legalAgreementDate: '',
      name: 'Platform',
      password: '',
      preferences: '',
      status: '',
      twoFactorAuth: '',
      type: 'Platform',
      username: 'Platform',
      organization: null,
    });
  }

  get regularUser$(): Observable<IUserAccount> {
    if (!this.currentUser$) {
      this.currentUser$ = this.authService.userProfile$.pipe(
        concatMap(account => {
          if (!account) {
            return;
          }
          const { email } = account;
          return this.getRegularUser(email);
        }),
        shareReplay(1)
      );
    }
    return this.currentUser$;
  }

  filterPlatform = (participantTypes: IParticipantType[]) =>
    participantTypes.filter(participantType => participantType.name !== 'PLATFORM');

  isPlatformUser = (): Observable<boolean> => {
    return this.platformService.isPlatformUser();
  };

  isNonViewer = (): Observable<boolean> => {
    return this.getUser().pipe(
      map((user: IUserAccount) => {
        if (user.entitlements?.includes('Editor') || user.entitlements?.includes('Reviewer')) return true;
        else return false;
      })
    );
  };

  getEncryptionKey = (): Observable<string> => {
    const actAs$ = this.authService.userActAs$;
    const platformId$ = this.platformService.getPlatformId();
    return zip(actAs$, platformId$).pipe(
      skipWhile(([[actAs], platformId]) => actAs.toLowerCase().includes(platformId)),
      concatMap(([[actAs]]) => {
        const url = environment.USERKEY.replace('{userId}', actAs);
        return this.http.get(url, { responseType: 'text' });
      })
    );
  };

  getParticipantTypes = (skipPlatform = false): Observable<IParticipantType[]> => {
    const method$ = this.http.get<IParticipantType[]>(environment.PARTICIPANTSTYPES);
    return skipPlatform
      ? method$.pipe(
          map(this.filterPlatform),
          catchError(() => of([]))
        )
      : method$.pipe(catchError(() => of([])));
  };

  getRegularUser = (email: string): Observable<IUserAccount> => {
    const url = environment.ORGANIZATIONUSERS.replace('{email}', email);
    return this.http.get<IUserAccount>(url);
  };

  getReviewers = (): Observable<IUserAccount[]> => {
    return this.http.get<IUserAccount[]>(environment.ORGANIZATIONSREVIEWERS);
  };

  getUser = (): Observable<IUserAccount> => {
    if (!this.user$) {
      this.user$ = this.isPlatformUser().pipe(
        mergeMap(isPlatformUser => iif(() => isPlatformUser, this.defaultPlatformUser$, this.regularUser$))
      );
    }
    return this.user$;
  };

  getUserId = (): Observable<string> => {
    if (!this.userId$) {
      this.userId$ = this.getUserIdByEmail().pipe(shareReplay(1));
    }
    return this.userId$;
  };

  getUserIdByEmail = (): Observable<string> => {
    const headers = new HttpHeaders({ [environment.avoidOrgId]: 'true' });
    return this.authService.userProfile$.pipe(
      concatMap(account => {
        return this.http.get(environment.USERID.replace('{email}', account.email), { responseType: 'text', headers });
      })
    );
  };

  getUserOrganization = (): Observable<string> => {
    return this.getUserId().pipe(
      map(userId => {
        return this.getUserLocalOrganization(userId);
      })
    );
  };

  getUserLocalOrganization = (userId: string): string => {
    const selectedUserOrg = this.window.localStorage.getItem(`${this.OCLabel}-${userId}`);
    return selectedUserOrg ? selectedUserOrg : null;
  };

  getUserOrganizations = (): Observable<IOrganization[]> => {
    if (!this.userOrganizations$) {
      this.userOrganizations$ = this.getUserId().pipe(
        concatMap(userId => {
          const headers = new HttpHeaders().set(environment.avoidOrgId, 'true');
          return this.http.get<IOrganization[]>(environment.USERORGANIZATIONS.replace('{userId}', userId), { headers });
        }),
        shareReplay(1)
      );
    }
    return this.userOrganizations$;
  };

  getUserOrganizationOptions = (): Observable<ICustomFormOption<string>[]> => {
    return this.getUserOrganizations().pipe(
      map(organizations => {
        return organizations.map(organization => {
          const { id, name } = organization;
          return { label: name, value: id };
        });
      })
    );
  };

  resetPassword = (): Observable<any> => {
    const user$ = this.authService.userProfile$;
    return user$.pipe(
      concatMap(user => {
        const { email } = user;
        return this.http.post(
          `https://${environment.AUTHCONF.domain}/dbconnections/change_password`,
          {
            email,
            connection: 'Username-Password-Authentication',
          },
          {
            responseType: 'text',
          }
        );
      })
    );
  };

  setUserLocalOrganization = (organizationId: string): Observable<string> => {
    return this.getUserId().pipe(
      tap(userId => {
        this.window.localStorage.setItem(`${this.OCLabel}-${userId}`, organizationId);
      }),
      tap(() => {
        this.userOrganizationChange.next();
      }),
      map(() => {
        return organizationId;
      })
    );
  };
}
