import { APP_INITIALIZER, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

import { AuthModule } from './auth/auth.module';
import { AlertModule } from './alert/alert.module';
import { LayoutsModule } from './layouts/layouts.module';
import { LoaderModule } from './loader/loader.module';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { OrganizationInterceptor } from './interceptors/organization.interceptor';
import { PlatformService } from './services/platform.service';
import { environment } from 'src/environments/environment';

export function initializeApp(platformService: PlatformService) {
  return platformService.initPlatformId();
}

@NgModule({
  imports: [CommonModule, AuthModule.forRoot(environment.AUTHCONF), AlertModule, LayoutsModule, LoaderModule, HttpClientModule],
  exports: [AlertModule, LayoutsModule, LoaderModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [PlatformService],
      multi: true,
    },
    HttpClientModule,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrganizationInterceptor,
      multi: true,
    },
  ],
})
export class CoreModule {}
