import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { MediaQueries } from '@core/models/media-queries';

@Injectable({
  providedIn: 'root',
})
export class MatchmediaService {
  mediaChange$ = new BehaviorSubject<string>(null);

  constructor(private window: Window) {}

  init = (): void => {
    Object.keys(MediaQueries).forEach(media => {
      const mediaQuery = this.window.matchMedia(MediaQueries[media]);
      if (mediaQuery.matches) {
        this.mediaChange$.next(MediaQueries[media]);
      }
      try {
        // Chrome & Firefox
        mediaQuery.addEventListener('change', event => this.handleMediaChange(event, MediaQueries[media]));
      } catch (error) {
        // Old Safari versions
        mediaQuery.addListener(event => this.handleMediaChange(event, MediaQueries[media]));
      }
    });
  };

  handleMediaChange = (event: MediaQueryListEvent, customMedia: string): void => {
    if (event.matches) {
      this.mediaChange$.next(customMedia);
    }
  };
}
