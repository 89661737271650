import { Component, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  encapsulation: ViewEncapsulation.None,
})
export class ModalComponent<T> {
  @Output() mainClick = new EventEmitter<T>();

  faXmark = faXmark;

  constructor(public activeModal: NgbActiveModal) {}

  close = (): void => {
    this.activeModal.dismiss('Modal dismissed');
  };
}
