export enum NotificationType {
  SERVICE_CONTRIBUTION_INVITATION = 'SERVICE_CONTRIBUTION_INVITATION',
  SUBSCRIBE_POLICY = 'SUBSCRIBE_POLICY',
  POLICY_UNSUBSCRIBE = 'POLICY_UNSUBSCRIBE',
  CREATE_POLICY = 'CREATE_POLICY',
  CREATE_DATASOURCE = 'CREATE_DATASOURCE',
  EDIT_DATASOURCE = 'EDIT_DATASOURCE',
  EDIT_POLICY = 'EDIT_POLICY',
  SERVICE_CONTRIBUTION_ALERT = 'SERVICE_CONTRIBUTION_ALERT',
  PRICE_CHANGE_POLICY = 'PRICE_CHANGE_POLICY',
  LICENSE_UPDATED = 'LICENSE_UPDATED',
  SUBSCRIBER_CONTRIBUTION = 'SUBSCRIBER_CONTRIBUTION',
  SUBSCRIBER_CONTRIBUTION_ERROR = 'SUBSCRIBER_CONTRIBUTION_ERROR',
  SQL_CONTRIBUTION = 'SQL_CONTRIBUTION',
  SQL_CONTRIBUTION_ERROR = 'SQL_CONTRIBUTION_ERROR',
  SUBSCRIPTION_EXPIRE = 'SUBSCRIPTION_EXPIRE',
  POLICY_DELIST = 'POLICY_DELIST',
  POLICY_PENDING_DELIST = 'POLICY_PENDING_DELIST',
  REVOKE_SUBSCRIPTION = 'CANCEL_POLICY_SUBSCRIPTION',
  EDIT_POLICY_CHANGE = 'EDIT_POLICY_CHANGE',
  DELETE_POLICY = 'DELETE_POLICY',
  DELETE_DATASOURCE = 'DELETE_DATASOURCE',
  REASSIGN_USER_GROUPS = 'REASSIGN_USER_GROUPS',
  REASSIGN_USER_ENTITLEMENTS = 'REASSIGN_USER_ENTITLEMENTS',
  PUBLISH_CONSENT = 'PUBLISH_CONSENT',
  LICNSE_KEY_UPDATE = 'LICNSE_KEY_UPDATE',
  EXPIRE_CAMPAIGN = 'EXPIRE_CAMPAIGN',
  CAMPAIGN_EXPIRED = 'CAMPAIGN_EXPIRED',
  CAMPAIGN_LIVE = 'CAMPAIGN_LIVE',
  EXPIRE_POLICY = 'EXPIRE_POLICY',
  POLICY_EXPIRED = 'POLICY_EXPIRED',
}
