export enum Status {
  CREATED = 'Created',
  LIVE = 'Live',
  PENDING = 'Pending',
  PENDINGCHANGES = 'Pending Changes',
  REJECTED = 'Rejected',
  APPROVED = 'Approved',
  CLOSED = 'Closed',
  INACTIVE = 'Inactive',
  DELETED = 'DELETED',
  INFO = 'INFO',
  EXPIRED = 'Expired',
}
