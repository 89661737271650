export enum DBTypes {
  'Postgres' = 'POSTGRESQL',
  'MySQL' = 'MYSQL',
  'Oracle' = 'ORACLE',
  'Snowflake' = 'SNOWFLAKE',
  'Redshift' = 'REDSHIFT',
  'Databricks' = 'DATABRICKS',
  'AWSS3' = 'AWSS3',
  'Synapse' = 'SYNAPSE',
  'Dremio' = 'DREMIO',
  'BigQuery' = 'BIGQUERY',
  'Presto' = 'PRESTO',
  'Sharepoint' = 'SHAREPOINT',
  'Kx' = 'KX',
}
