import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { concatMap, catchError, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { Subject, of } from 'rxjs';

import { IGroup } from '@core/models/group.model';
import { IOrganization } from '@core/models/organization.model';
import { environment } from 'src/environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private groupsChange = new Subject<void>();
  groupsChange$ = this.groupsChange.asObservable();

  constructor(private http: HttpClient, private userService: UserService) {}

  getGroups = (): Observable<IGroup[]> => {
    return this.userService.getUserOrganization().pipe(
      concatMap(organizationId => {
        const url = environment.ORGANIZATIONGROUPS.replace('{organizationId}', organizationId);
        return this.http.get<IGroup[]>(url);
      }),
      catchError(() => of([]))
    );
  };

  getCleanGroups(groupsAsListJSON: string, groupsASJON: IGroup[]): IGroup[] {
    const parsedGroups = JSON.parse(groupsAsListJSON);
    return groupsASJON.map(group => {
      const { parentGroupId, organizationId, name, id, path } = group;
      return {
        id,
        name,
        organizationId,
        parentGroupId,
        path: path?.length ? path : this.getParsedPath(parsedGroups, name),
      };
    });
  }

  getFlattenGroups = (groups: IGroup[], flatGroups: IGroup[] = []): IGroup[] => {
    for (const group of groups) {
      flatGroups = [...flatGroups, group];
      if (group?.groups?.length > 0) {
        flatGroups = this.getFlattenGroups(group.groups, flatGroups);
      }
    }
    return flatGroups;
  };

  getOrganization = (organizationId: string): Observable<IOrganization> => {
    const url = environment.ORGANIZATION.replace('{organizationId}', organizationId);
    return this.http.get<IOrganization>(url);
  };

  getParsedPath = (parsedGroups: IGroup[], groupName): string[] => {
    return parsedGroups.find(group => group.name === groupName).path;
  };

  getUserOrganization = (): Observable<IOrganization> => {
    const organizationId$ = this.userService.getUserOrganization();
    return organizationId$.pipe(
      concatMap(orgId => {
        return this.getOrganization(orgId);
      })
    );
  };

  getOrganizations = (): Observable<IOrganization[]> => {
    return this.http.get<IOrganization[]>(environment.ORGANIZATIONS).pipe(catchError(() => of([])));
  };

  inviteOrganization = (email: string): Observable<any> => {
    return this.userService.getUser().pipe(
      concatMap(user => {
        const { organization } = user;
        return this.http.post(`${environment.POLICIES}/sendInvitation`, { organization, email });
      })
    );
  };

  updateOrganization = (organization: IOrganization): Observable<IOrganization> => {
    organization = { ...organization, participantType: null };
    const url = environment.ORGANIZATION.replace('{organizationId}', organization.id);
    return this.http.put<IOrganization>(url, organization);
  };

  updateOrganizationGroups = (groups: IGroup[]): Observable<IOrganization> => {
    const organizationId$ = this.userService.getUserOrganization();
    return organizationId$.pipe(
      concatMap(orgId => {
        return this.getOrganization(orgId);
      }),
      concatMap(organization => {
        const { groupsAsListJSON } = organization;
        const flatGroups = this.getFlattenGroups(groups, []);
        const cleanGroups = this.getCleanGroups(groupsAsListJSON, flatGroups);
        const newValue = JSON.stringify(cleanGroups);
        organization = { ...organization, groupsAsListJSON: newValue };
        return this.updateOrganization(organization);
      }),
      tap(() => this.groupsChange.next())
    );
  };
}
