// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.

import { getCommonConf } from './common';

// The list of file replacements can be found in `angular.json`.
const apiURL = 'https://api-dev.one-creation.com/';
const commonConf = getCommonConf(apiURL);
export const environment = {
  production: false,
  grafanaEnabled: true,
  grafanaUrl: 'https://faro-collector-prod-us-east-0.grafana.net/collect/19ddd88077830c48566fd94ac6ddf4bc',
  grafanaApp: 'Dev frontend',
  env: 'dev',
  URL: 'api-dev.one-creation.com/',
  AUTHCONF: {
    domain: 'auth-dev.one-creation.com',
    clientId: 'hmcFHxuLiKHBH8mwRPebSX8cjCkHrNxI',
    returnTo: 'https://dev.one-creation.com/',
    // The AuthHttpInterceptor configuration
    httpInterceptor: {
      allowedList: [
        // Attach access tokens to any calls to '/api' (exact match)
        `${apiURL}api`,

        // Attach access tokens to any calls that start with '/api/'
        `${apiURL}api/*`,
      ],
    },
  },
  ...commonConf,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
