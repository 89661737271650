import { Injectable } from '@angular/core';

import { Observable, zip, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { IAdvancedRule } from '@advanced-rules/advanced-rule.model';
import { IRecipients } from '../../catalog/catalog-create-policy/recipients.model';
import { IGridRow } from '@core/models/grid-row.model';
import { IGroup } from '@core/models/group.model';
import { IOrganization } from '@core/models/organization.model';
import { IParticipantType } from '@core/models/participant-type.model';
import { IRule } from '@core/models/rules.model';
import { IUserAccount } from '@core/models/user-account.model';
import { ICustomFormOption } from '@shared/features/custom-forms/custom-form-option.model';
import { isLevelZeroSourceType } from '@shared/helpers/helpers';
import { UserService } from '@shared/services/user.service';
import { OrganizationService } from '@shared/services/organization.service';

@Injectable({
  providedIn: 'root',
})
export class RecipientsService {
  constructor(protected organizationService: OrganizationService, protected userService: UserService) {}

  get groupedRecipients(): Observable<any> {
    const groups$ = this.getGroups();
    const organizations$ = this.getOrganizations();
    const participantTypes$ = this.getParticipantTypes();
    return zip(groups$, organizations$, participantTypes$);
  }

  private filterPlatform = (participantTypes: IParticipantType[]) =>
    participantTypes.filter(participantType => participantType.name !== 'PLATFORM');

  private handleRecipients = (
    recipients: (IParticipantType | IGroup | IOrganization)[],
    groupName: string = null
  ): ICustomFormOption<string>[] => {
    return recipients.map((recipient: any) => {
      const item = {
        label: recipient.name,
        value: recipient.id,
        selected: false,
      };
      return groupName
        ? {
            ...item,
            group: groupName,
          }
        : {
            ...item,
          };
    });
  };

  getHeaders = (fields: IGridRow[]): ICustomFormOption<string>[] => {
    const filteredFields = fields.filter(field => {
      const parsedField = parseInt(field.level as string);
      return parsedField === 1;
    });
    return filteredFields.length
      ? filteredFields.map(this.handleHeader)
      : fields
          .filter(field => {
            const parsedField = parseInt(field.level as string);
            return parsedField === 0;
          })
          .map(this.handleHeader);
  };

  getGroups = (): Observable<ICustomFormOption<string>[]> => {
    return this.userService.getUser().pipe(
      concatMap(this.getGroupsAndTypes),
      map(groups => {
        return this.handleRecipients(groups, 'internal');
      }),
      catchError(() => of([]))
    );
  };

  getGroupsAndTypes = (userAccount: IUserAccount) => {
    const { type } = userAccount;
    return this.organizationService.getGroups().pipe(map(departments => departments.map(department => ({ ...department, type }))));
  };

  getOrganizations = (): Observable<ICustomFormOption<string>[]> =>
    this.organizationService.getOrganizations().pipe(
      map(organizations => {
        return this.handleRecipients(organizations, 'organizations');
      }),
      catchError(() => of([]))
    );

  getParticipantTypes = (): Observable<ICustomFormOption<string>[]> =>
    this.userService.getParticipantTypes().pipe(
      map(this.filterPlatform),
      map(types => {
        return this.handleRecipients(types, 'types');
      }),
      catchError(() => of([]))
    );

  getRecipients = (): Observable<IRecipients> => {
    return this.groupedRecipients.pipe(
      map(([groups, organizations, participantTypes]) => {
        return {
          groups,
          organizations,
          participantTypes,
        };
      })
    );
  };

  getRecipientOptions = (): Observable<ICustomFormOption<string>[]> => {
    return this.groupedRecipients.pipe(
      map(([groups, organizations, participantTypes]) => {
        return [...groups, ...organizations, ...participantTypes];
      })
    );
  };

  getAdvancedRules = (rules: IRule[]): IAdvancedRule[] => {
    return rules?.map((rule: IRule) => {
      const { shareWith, attributes, conditions } = rule;
      const payload = {
        selectedRecipients: shareWith,
        canReceive: attributes.map(item => {
          return item.path[1];
        }),
      };
      return conditions.length
        ? {
            ...payload,
            conditions: conditions.map(condition => {
              const { column, operator, value } = condition;
              return {
                attribute: column,
                operation: operator,
                value,
              };
            }),
          }
        : payload;
    });
  };

  getRules = (rules: IAdvancedRule[], fee: number = null): IRule[] => {
    return rules.map((rule: IAdvancedRule) => {
      const { selectedRecipients, canReceive, conditions } = rule;
      const payload = {
        shareWith: selectedRecipients,
        attributes: canReceive.map(item => {
          return {
            path: ['Data', item],
            fee,
          };
        }),
      };
      return conditions.length
        ? {
            ...payload,
            conditions: conditions.map(condition => {
              const { attribute, operation, value } = condition;
              return {
                column: attribute,
                operator: operation,
                value,
              };
            }),
          }
        : payload;
    });
  };

  handleHeader = (field: IGridRow): ICustomFormOption<string> => {
    const parsedField = parseInt(field.level as string);
    return {
      label: field.path[parsedField],
      value: field.path[parsedField],
    };
  };

  isLevelZeroSourceType = (source: string): boolean => {
    return isLevelZeroSourceType(source);
  };
}
