export enum AlertType {
  Success = 'success',
  Error = 'error',
  Info = 'info',
  Warning = 'warning',
}
export class IAlert {
  id: string;
  title: string;
  type: AlertType;
  message: string;
  autoClose: boolean;
  keepAfterRouteChange: boolean;
  fade: boolean;
  class?: string;

  constructor(init?: Partial<IAlert>) {
    Object.assign(this, init);
  }
}
