<app-modal>
  <main>
    <h1>Profile</h1>
    <br />
    <div class="my-account__block">
      <div class="my-account__title">
        <p>My Username</p>
      </div>
      <div class="my-account__content">
        <p>{{ user?.username }}</p>
      </div>
    </div>
    <div class="my-account__block">
      <div class="my-account__title">
        <p>Name</p>
      </div>
      <div class="my-account__content">
        <p>{{ user?.name }}</p>
      </div>
    </div>
    <div class="my-account__block">
      <div class="my-account__title">
        <p>Organization Name</p>
      </div>
      <div class="my-account__content">
        <p>{{ user?.organization?.name }}</p>
      </div>
    </div>
    <div class="my-account__block">
      <div class="my-account__title">
        <p>Organization ID</p>
      </div>
      <div class="my-account__content">
        <p>{{ user?.organization?.id }}</p>
      </div>
    </div>
    <div class="my-account__block">
      <div class="my-account__title">
        <p>Primary Email</p>
      </div>
      <div class="my-account__content">
        <p>{{ user?.email }}</p>
      </div>
    </div>
    <div class="my-account__block">
      <div class="my-account__title">
        <p>Role</p>
      </div>
      <div class="my-account__content">
        <ul>
          <li *ngFor="let entitlement of user?.entitlements">{{ entitlement }}</li>
        </ul>
      </div>
    </div>
    <div class="my-account__block">
      <div class="my-account__title">
        <p>Participant Type</p>
      </div>
      <div class="my-account__content">
        <p>{{ user?.organization?.participantType?.name }}</p>
      </div>
    </div>
    <div class="my-account__block" *ngIf="encryptionKey">
      <div class="my-account__title">
        <p>Encryption key</p>
      </div>
      <div class="my-account__content">
        <p class="my-account__encryption-key">{{ showEncryptionKey ? encryptionKey : '********' }}</p>
        <button class="app-btn app-btn--primary" (mousedown)="showEncryptionKey = true" (mouseup)="showEncryptionKey = false">
          <fa-icon [icon]="faEye"></fa-icon>
        </button>
        <button class="app-btn app-btn--primary" (click)="copyEncryptionKey()"><fa-icon [icon]="faClipboard"></fa-icon></button>
      </div>
    </div>
    <div class="my-account__block">
      <div class="my-account__title">
        <p>Password</p>
      </div>
      <div class="my-account__content">
        <p>
          <button class="app-btn app-btn--primary" (click)="resetPassword()">{{ 'Reset' | translate }}</button>
        </p>
      </div>
    </div>
    <div class="my-account__block">
      <div class="my-account__title">
        <p>Two-Factor Authentication</p>
      </div>
      <div class="my-account__content">
        <p>
          <button class="app-btn app-btn--primary" disabled>{{ 'Enable' | translate }}</button>
        </p>
      </div>
    </div>
    <br />
    <div class="my-account__actions">
      <button class="app-btn app-btn--primary" (click)="dismiss()">{{ 'Close' | translate }}</button>
      <button class="app-btn app-btn--secondary" (click)="logout()">
        <fa-icon [icon]="faPowerOff"></fa-icon>{{ 'Logout' | translate }}
      </button>
    </div>
  </main>
</app-modal>
