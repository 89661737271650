export enum SubscriptionType {
  MONTHLY = 'Monthly',
  ANNUAL = 'Annual',
  ONETIME = 'One Time',
}

export enum SubscriptionTypeShortened {
  MONTHLY = 'mo',
  ANNUAL = 'yr',
  ONETIME = 'one time',
}
