export const getCommonConf = (apiURL, adminEmail = 'admin@one-creation.com') => {
  return {
    adminEmail,
    inactivityThreshold: 3600,
    avoidLoaderHeader: 'One-Creation-Avoid-Loader',
    avoidErrorHandler: 'One-Creation-Avoid-Error',
    avoidAuth: 'One-Creation-Avoid-Auth-Interceptor',
    avoidOrgId: 'One-Creation-Avoid-Org-Interceptor',
    apiURL,

    /* DATASOURCES */
    DATASOURCES: `${apiURL}v2/datasources`,
    DATASOURCE: `${apiURL}v2/datasources/{datasourceId}/{organizationId}`,
    DATASOURCEDELETE: `${apiURL}v2/datasources/{datasourceId}`,
    APPROVEDATASOURCE: `${apiURL}v2/datasources/{datasourceId}/approve`,
    REJECTDATASOURCE: `${apiURL}v2/datasources/{datasourceId}/reject`,
    GETDBTABLES: `${apiURL}v2/datasources/get-database-tables`,
    GETDBTABLESCOLUMS: `${apiURL}v2/datasources/get-database-columns`,
    CONTRIBUTEDATA: `${apiURL}v2/datasources/{datasourceId}/contribute-data/{publisherId}`,
    GETS3FILES: `${apiURL}v2/datasources/S3bucket`,
    DOWNLOADS3FILES: `${apiURL}v2/datasources/download-s3-file`,
    GETSHAREPOINTSITES: `${apiURL}v2/datasources/sharepoint-sites`,
    GETSHAREPOINTAUTH: `${apiURL}v2/datasources/sharepoint-authentication`,
    GETSHAREPOINTROOT: `${apiURL}v2/datasources/sharepoint-root`,
    GETSHAREPOINTFOLDERS: `${apiURL}v2/datasources/sharepoint-folders`,
    DATASOURCESDOWNLOADCONTRIBUTION: `${apiURL}v2/datasources/{datasourceId}/{contributionId}`,

    /* GROUPS */
    GROUPS: `${apiURL}v1/groups`,
    GROUP: `${apiURL}v1/groups/{groupId}`,
    COGNITOGROUPS: `${apiURL}v1/users/cognito-groups`,

    /* NOTIFICATIONS */
    MYREQUESTS: `${apiURL}v1/notifications?filter=active`,
    MYPENDINGREQUESTS: `${apiURL}v1/notifications?filter=pending`,

    CLOSE: `${apiURL}v1/notifications/close/{notificationId}`,
    CLEARNOTIFICATIONS: `${apiURL}v1/notifications/close`,

    /* ORGANIZATIONS */
    ORGANIZATIONS: `${apiURL}v1/organizations`,
    ORGANIZATION: `${apiURL}v1/organizations/{organizationId}`,
    ORGANIZATIONGROUPS: `${apiURL}v1/organizations/{organizationId}/groups`,
    ORGANIZATIONSUSERS: `${apiURL}v1/organizations/users?adminEmail={email}`,
    ORGANIZATIONSADMINS: `${apiURL}v1/organizations/admins`,
    ORGANIZATIONSREVIEWERS: `${apiURL}v1/organizations/{organizationId}/reviewer-list`,

    /* PARTICIPANT-TYPE */
    PARTICIPANTSTYPES: `${apiURL}v1/participant-types`,
    PARTICIPANTTYPES: `${apiURL}v1/participant-types/{participantId}`,

    /* POLICIES */
    POLICY: `${apiURL}v2/policies/{policyId}/{organizationId}`,
    POLICYDELETE: `${apiURL}v2/policies/{policyId}`,
    POLICIES: `${apiURL}v2/policies`,
    DELISTPOLICY: `${apiURL}v2/policies/{policyId}/delist`,
    APPROVEDELISTPOLICY: `${apiURL}v2/policies/{policyId}/delist/approve`,
    REJECTDELISTPOLICY: `${apiURL}v2/policies/{policyId}/delist/reject`,
    DELISTEDPOLICIES: `${apiURL}v2/policies/delisted`,
    EDITPOLICY: `${apiURL}v2/policies/{policyId}/edit`,
    APPROVEEDITPOLICY: `${apiURL}v2/policies/{policyId}/edit/approve`,
    REJECTEDITPOLICY: `${apiURL}v2/policies/{policyId}/edit/reject`,
    USAGEPOLICY: `${apiURL}v2/policies/{policyId}/reports/usage`,
    SUBSCRIBEPOLICY: `${apiURL}v2/policies/{policyId}/{organizationId}/subscribe`,
    UNSUBSCRIBEPOLICY: `${apiURL}v2/policies/{policyId}/{organizationId}/unsubscribe`,
    APPROVESUBSCRIPTION: `${apiURL}v2/policies/{policyId}/subscription/{subscriber}/{organizationId}/approve`,
    REJECTSUBSCRIPTION: `${apiURL}v2/policies/{policyId}/subscription/{subscriber}/{organizationId}/reject`,
    APPROVEPOLICY: `${apiURL}v2/policies/{policyId}/approve`,
    REJECTPOLICY: `${apiURL}v2/policies/{policyId}/reject`,
    DECRYPT: `${apiURL}v2/policies/{organizationId}/decrypt`,
    PREVIEWDATA: `${apiURL}v2/policies/{policyId}/{organizationId}/detail/preview`,
    PREVIEWDATASUSCRIBER: `${apiURL}v2/policies/{policyId}/{subscriber}/{organizationId}/detail/preview`,
    CATALOG: `${apiURL}v2/policies/catalog`,
    DOWNLOADFILEPOLICIES: `${apiURL}v2/policies/{policyId}/{organizationId}/data/{contributionId}`,
    DOWNLOADFILEPOLICIESSHAREPOINT: `${apiURL}v2/policies/{policyId}/{organizationId}/data/sharepoint`,
    DOWNLOADREALTIMEFILEPOLICIES: `${apiURL}v2/policies/{policyId}/{organizationId}/data`,
    DOWNLOADUNENCRYPTEDFILESHAREPOINT: `${apiURL}v2/policies/{policyId}/{organizationId}/data/sharepoint/unencrypted`,
    DOWNLOADUNENCRYPTEDFILE: `${apiURL}v2/policies/{policyId}/{organizationId}/data/{contributionId}/unencrypted`,
    DOWNLOADREALTIMEUNENCRYPTEDFILE: `${apiURL}v2/policies/{policyId}/{organizationId}/data/unencrypted`,
    DOWNLOADLICENSEPOLICIES: `${apiURL}v2/policies/{policyId}/{organizationId}/download-license`,
    MYPOLICIES: `${apiURL}v1/subscribers?organizationId={organizationId}`,
    MYPOLICIESGRAPH: `${apiURL}v1/subscribers/graph?organizationId={organizationId}`,
    MYSUBSCRIPTIONS: `${apiURL}v1/subscriptions?organizationId={organizationId}`,
    MYSUBSCRIPTIONSGRAPH: `${apiURL}v1/subscriptions/graph?organizationId={organizationId}`,
    POLICYCONTRIBUTIONS: `${apiURL}v2/policies/{policyId}/contributions`,

    /* SUBSCRIPTIONS*/
    LICENSES: `${apiURL}v1/subscriptions/{subscriptionId}/contract/{subscriber}/{organizationId}`,
    REVOKESUBSCRIPTION: `${apiURL}v1/subscriptions/revoke`,
    UNSUBSCRIBEDPOLICIES: `${apiURL}v1/subscriptions/unsubscribed`,
    SUBSCRIBEDPOLICIES: `${apiURL}v2/policies/subscriptions`,
    GETPENDINGSUBSCRIPTIONS: `${apiURL}v2/policies/pending-subscriptions`,

    /* USERS */
    USERS: `${apiURL}v1/users`,
    USER: `${apiURL}v1/users/{userId}`,
    USERKEY: `${apiURL}v1/users/{userId}/user-key`,
    ORGANIZATIONUSERS: `${apiURL}v1/users?email={email}&organizationId={organizationId}`,
    PLATFORMUSERID: `${apiURL}v1/users/${adminEmail}/id`,
    USERID: `${apiURL}v1/users/{email}/id`,
    USERORGANIZATIONS: `${apiURL}v1/users/get-organizationsFromUser?userId={userId}`,
    REGISTER: `${apiURL}v1/users/register`,
    REGISTERCONFIRMATION: `${apiURL}v1/users/register-confirmation`,
    REINVITATION: `${apiURL}v1/users/{userId}/{orgId}/invitation`,
    BULKINVITATION: `${apiURL}v1/users/register-csv`,
    COGNITO: `${apiURL}v1/users/register-cognito`,
    AMZNSIGNUP: `${apiURL}v1/connector/register`,

    /* SOCKET */
    WEBSOCKETEVENTSOURCE: `${apiURL}events/subscribe`,

    /* To be removed */
    RELISTPOLICY: `${apiURL}api/marketplace/policies/list?id={id}&publisher={publisher}`,
    APROVECONTRIBUTIONINVITATION: `${apiURL}api/marketplace/policies/contribution/accept`,

    /* CONSENT */
    CONSENTCAMPAIGNCREATE: `${apiURL}v1/campaigns/{organizationId}`,
    CONSENTCAMPAIGN: `${apiURL}v1/campaigns/{campaignId}`,
    CONSENTCAMPAIGNCONDITIONS: `${apiURL}v1/campaigns/conditions/{campaignId}`,
    CONSENTCAMPAIGNCONDITIONSSAVE: `${apiURL}v1/campaigns/conditions`,
    CONSENTCAMPAIGNSLIST: `${apiURL}v1/campaigns/get-campaigns-by-dataset/{datasetId}`,
    CONSENTCAMPAIGNPOLICY: `${apiURL}v1/campaigns/get-campaign-dataset-policy-id/{campaignId}/{datasetId}`,
    CONSENTCAMPAIGNPUBLISH: `${apiURL}v1/campaigns/{campaignId}/{organizationId}/publish`,
    CONSENTCAMPAIGNEDIT: `${apiURL}v1/campaigns/{campaignId}/{organizationId}/edit`,
    CONSENTCAMPAIGNREQUEST: `${apiURL}v1/campaigns/{campaignId}/{publisherOrganizationId}/consent`,
    CONSENTCAMPAIGNDELETE: `${apiURL}v1/campaigns/{campaignId}`,
    CONSENTCAMPAIGNDEACTIVATE: `${apiURL}v1/campaigns/{campaignId}/deactivate`,
    CONSENTCAMPAIGNSTATUSREPORT: `${apiURL}v1/campaigns/{campaignId}/status-report`,
    CONSENTCAMPAIGNSURVEYREPORT: `${apiURL}v1/campaigns/{campaignId}/status-report`,
    CONSENTCAMPAIGNREJECT: `${apiURL}v1/campaigns/{campaignId}/publish/reject`,
    CONSENTCAMPAIGNAPPROVE: `${apiURL}v1/campaigns/{campaignId}/{organizationId}/publish/approve`,
    CONSENTCAMPAIGNSURVEYCSV: `${apiURL}v1/campaigns/survey/{campaignId}?version={version}`,
    CONSENTCAMPAIGNSURVEYRESPONSES: `${apiURL}v1/campaigns/survey/{campaignId}?responses=true&version={version}`,
    CONSENTCAMPAIGNSURVEYNONAUTH: `${apiURL}v1/campaigns/na/{campaignId}`,
    CONSENTCAMPAIGNSURVEYPROCESSOR: `${apiURL}survey-processor`,
    CONSENTCAMPAIGNSURVEYS: `${apiURL}v1/campaigns/survey-versions/{campaignId}`,
    CONSENTCAMPAIGNCONSENT: `${apiURL}v1/campaigns/update-contact-permission`,

    /* LICENSE */
    LICENSEORGANIZATION: `${apiURL}v1/license/organizations/{organizationId}/licenses`,
    LICENSEORGANIZATIONBULK: `${apiURL}v1/license/organizations/licenses`,
    LICENSEMETER: `${apiURL}v1/license/organizations/{organizationId}/rate-limits`,

    /** CONTACTS */
    CONTACTSPOST: `${apiURL}v1/users/save-contact`,
    CONTACTSGET: `${apiURL}v1/users/get-contacts?organizationId={organizationId}`,
    CONTACTSGETAGS: `${apiURL}v1/users/get-tags?organizationId={organizationId}`,
    CONTACTSDELETE: `${apiURL}v1/users/delete-contact?organizationId={organizationId}&email={email}`,
    CONTACTSSAVELIST: `${apiURL}v1/users/save-contact-list`,
    CONTACTSREADCSVLIST: `${apiURL}v1/users/read-contacts-csv`,

    /** CSV TEMPLATE */
    CSV_TEMPLATE: 'https://oc-logos-dev01.s3.eu-central-1.amazonaws.com/template.csv',
    CSV_CONTACT_TEMPLATE: 'https://oc-logos-dev01.s3.eu-central-1.amazonaws.com/contact-template.csv',

    /** WEBHOOKS */
    WEBHOOKGETALL: `${apiURL}v1/campaigns/all-webhook/{organizationId}`,
    WEBHOOKGET: `${apiURL}v1/campaigns/webhook/{webhookId}`,
    WEBHOOKPOST: `${apiURL}v1/campaigns/webhook`,
    WEBHOOKPUT: `${apiURL}v1/campaigns/webhook`,
    WEBHOOKDELETE: `${apiURL}v1/campaigns/webhook/{webhookId}`,
    WEBHOOKCAMPAIGNPOST: `${apiURL}v1/campaigns/campaign-webhook`,
    WEBHOOKCAMPAIGNDELETE: `${apiURL}v1/campaigns/campaign-webhook/{campaignId}/{webhookId}`,

    /* KLAVIYO */
    KLAVIYOAUTHORIZE: `${apiURL}v1/campaigns/integration/authentication?name={name}`,
    KLAVIYOCALLBACK: `${apiURL}v1/campaigns/oauth/klaviyo/callback?code={code}&state={state}`,
    KLAVIYOTESTCONNECTION: `${apiURL}v1/campaigns/klaviyo/accounts`,
    KLAVIYOREVOKETOKEN: `${apiURL}v1/campaigns/oauth/klaviyo/revoke`,
    KLAVIYOSTRATEGYPOST: `${apiURL}v1/campaigns/save-klaviyo-strategy`,

    /** PREFERENCECENTER */
    PREFERENCECENTER: `${apiURL}v1/preference-center`,
    PREFERENCECENTERTHUMBNAIL: `${apiURL}v1/preference-center/thumbnail`,
    PREFERENCECENTERPLATFORM: `${apiURL}v1/preference-center/platform`,
    PREFERENCECENTERGET: `${apiURL}v1/preference-center/{EMAIL}`,
    KLAVIYOSTRATEGYGET: `${apiURL}v1/campaigns/klaviyo-strategy`,

    /** CREDENTIALS */
    CLIENTCREDENTIALS: `${apiURL}v1/organizations/integration`,

    /* SEGMENT */
    SEGMENTAUTHORIZE: `${apiURL}v1/segment/integrate`,
    SEGMENTCONNECTIONTEST: `${apiURL}v1/segment/get-account`,
    SEGMENTREVOKE: `${apiURL}v1/segment/revoke`,

    /* EFS */
    EFS: `${apiURL}v3/files`,
  };
};
