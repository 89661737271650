import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map, share, tap } from 'rxjs/operators';
import { Observable, zip, Subject } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { IEditPolicyOutput } from '@core/models/edit-policy-output.model';
import { IPolicy } from '@core/models/policy.model';
import { IPolicyContribution } from '@core/models/policy-contribution.model';
import { IPaginationResponse } from '@core/models/pagination-response.model';
import { Status } from '@core/models/status';
import { ISubscription } from '@core/models/subscription.model';
import { IPagination } from '@core/models/pagination-params';
import { buildPaginationParams, replacer } from '@shared/helpers/helpers';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PolicyService {
  private policyChange = new Subject<string>();
  policyChange$ = this.policyChange.asObservable();

  constructor(private authService: AuthService, private http: HttpClient) {}

  approvePolicy = (id: string): Observable<any> => {
    const url = environment.APPROVEPOLICY.replace('{policyId}', id);
    return this.http.post(url, {}, { responseType: 'text' }).pipe(tap(() => this.policyChange.next(id)));
  };

  approveEditPolicy = (id: string): Observable<any> => {
    const url = environment.APPROVEEDITPOLICY.replace('{policyId}', id);
    return this.http.post(url, {}, { responseType: 'text' }).pipe(tap(() => this.policyChange.next(id)));
  };

  contributePolicy = (contribution: IPolicyContribution): Observable<any> => {
    return this.http.post(environment.APROVECONTRIBUTIONINVITATION, contribution);
  };

  deletePolicy = (id: string): Observable<any> => {
    const url = environment.POLICYDELETE.replace('{policyId}', id);
    return this.http.delete(url, { responseType: 'text' }).pipe(tap(() => this.policyChange.next(id)));
  };

  delistPolicy = (id: string): Observable<any> => {
    const url = environment.DELISTPOLICY.replace('{policyId}', id);
    return this.http.post(url, {}, { responseType: 'text' });
  };

  getMyPolicies = (params?: IPagination, options = {}): Observable<IPaginationResponse<IPolicy>> => {
    return this.http.get<IPaginationResponse<IPolicy>>(`${environment.POLICIES}${buildPaginationParams(params)}`, options).pipe(share());
  };

  getMyDelistedPolicies = (params?: IPagination, options = {}): Observable<IPaginationResponse<IPolicy>> => {
    return this.http.get<IPaginationResponse<IPolicy>>(`${environment.DELISTEDPOLICIES}${buildPaginationParams(params)}`, options);
  };

  getMyActivePolicies = (): Observable<IPolicy[]> => {
    const actAs$ = this.authService.userActAs$;
    const myPolicies$ = this.getMyPolicies();
    return zip(actAs$, myPolicies$).pipe(
      map(([actAs, policies]) => {
        const { list } = policies;
        return list.filter((policy: IPolicy) => {
          const { status, publisher } = policy;
          return status === Status.APPROVED && actAs.indexOf(publisher) !== -1;
        });
      })
    );
  };

  getPolicy = (id: string): Observable<IPolicy> => {
    const url = environment.POLICY.replace('{policyId}', id);
    return this.http.get<IPolicy>(url);
  };

  getPolicies = (params?: IPagination, options = {}): Observable<IPaginationResponse<IPolicy>> =>
    this.http.get<IPaginationResponse<IPolicy>>(`${environment.CATALOG}${buildPaginationParams(params)}`, options);

  getUnsubscribedPolicies = (params?: IPagination, options = {}): Observable<IPaginationResponse<ISubscription>> => {
    return this.http.get<IPaginationResponse<ISubscription>>(
      `${environment.UNSUBSCRIBEDPOLICIES}${buildPaginationParams(params)}`,
      options
    );
  };

  relistPolicy = (id: string, publisher: string): Observable<any> => {
    const url = environment.RELISTPOLICY.replace('{id}', id).replace('{publisher}', publisher);
    return this.http.post(url, {}, { responseType: 'text' });
  };

  rejectPolicy = (id: string): Observable<any> => {
    const url = environment.REJECTPOLICY.replace('{policyId}', id);
    return this.http.post(url, {}, { responseType: 'text' }).pipe(tap(() => this.policyChange.next(id)));
  };

  rejectEditPolicy = (id: string): Observable<any> => {
    const url = environment.REJECTEDITPOLICY.replace('{policyId}', id);
    return this.http.post(url, {}, { responseType: 'text' }).pipe(tap(() => this.policyChange.next(id)));
  };

  approveDelistPolicy = (id: string): Observable<any> => {
    const url = environment.APPROVEDELISTPOLICY.replace('{policyId}', id);
    return this.http.post(url, {}, { responseType: 'text' }).pipe(tap(() => this.policyChange.next(id)));
  };

  rejectDelistPolicy = (id: string): Observable<any> => {
    const url = environment.REJECTDELISTPOLICY.replace('{policyId}', id);
    return this.http.post(url, {}, { responseType: 'text' }).pipe(tap(() => this.policyChange.next(id)));
  };

  updatePolicy = (policyId: string, policyEditDTO: IEditPolicyOutput): Observable<any> => {
    const formData = new FormData();
    formData.append('policyId', policyId);
    const policyDTO = new Blob([JSON.stringify(policyEditDTO, replacer)], {
      type: 'application/json',
    });
    formData.append('policyEditDTO', policyDTO);
    const url = environment.EDITPOLICY.replace('{policyId}', policyId);
    return this.http.post(url, formData).pipe(tap(() => this.policyChange.next(policyId)));
  };
}
