import { Injectable } from '@angular/core';

import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class GridService {
  get defaultLevel() {
    return {
      level: 0,
      path: ['Root'],
      format: 'String',
      type: 'folder',
    };
  }

  private obtainCellLetters(sheet: any) {
    const letters = [];
    const keys = Object.keys(sheet);
    keys.shift();
    keys.pop();
    keys.forEach(v => {
      letters.push(v.replace(/[^a-z]/gi, ''));
    });
    return [...new Set(letters)];
  }

  private obtainColumns(sheet: any) {
    const columns = [];
    const letters = this.obtainCellLetters(sheet);
    letters.forEach(letter => {
      const column = Object.keys(sheet)
        .filter(val => {
          if (val.replace(/[^a-z]/gi, '') === letter) {
            return sheet[val];
          }
        })
        .map(val => sheet[val]);
      columns.push(column);
    });
    return columns;
  }

  private obtainLevelFormat(level) {
    const dateRegex = /^(\d{2,4}|\w+)[\/\-\.](\d{2,4}|\w+)[\/\-\.](\d{2,4}|\w+$)/;
    let format: string;
    if (level.t === 'd' && dateRegex.test(level.w)) {
      format = 'date';
    } else if (level.t === 'n') {
      format = 'number';
    } else {
      format = 'string';
    }
    return format;
  }

  private createLevels(columns, sheetName) {
    if (!sheetName) {
      return [];
    }
    const levels = [];
    columns.forEach(column => {
      column.forEach((level, index) => {
        if (!column[0]?.w || !level?.w) {
          return;
        }
        levels.push({
          level: index === 0 ? 1 : 2,
          path: index === 0 ? [sheetName, column[0].w] : [sheetName, column[0].w, level.w],
          format: this.obtainLevelFormat(level),
          type: index === 0 ? 'folder' : '',
        });
      });
    });
    return levels;
  }

  private cleanLevels(levels) {
    let auxNewRowData = [];
    auxNewRowData = levels.map(row => JSON.stringify(row));
    auxNewRowData = [...new Set(auxNewRowData)];
    return auxNewRowData.map(row => JSON.parse(row));
  }

  public getLevels = (data: string | ArrayBuffer): any[] => {
    let levels = [];
    try {
      const workBook = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'dd/mm/yyyy' });
      workBook.SheetNames.forEach(sheetName => {
        const sheet = workBook.Sheets[sheetName];
        const columns = this.obtainColumns(sheet);
        const moreLevels = this.createLevels(columns, sheetName);
        levels = [
          ...levels,
          {
            ...this.defaultLevel,
            path: [sheetName],
          },
        ];
        /**
         * Using for loop to avoid Maximum call stack size exceeded
         * when trying to push larger arrays together
         */
        for (const level of moreLevels) {
          levels.push(level);
        }
      });
    } catch (error) {
      levels = [this.defaultLevel];
      console.warn(error);
    }
    return this.cleanLevels(levels);
  };
}
