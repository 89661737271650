import { HttpHeaders } from '@angular/common/http';

import { Observable, from } from 'rxjs';
import { concatMap } from 'rxjs/operators';

const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

import { ICatalogPolicy } from '../../catalog/catalogPolicy.model';
import { MediaQueries } from '@core/models/media-queries';
import { IPagination } from '@core/models/pagination-params';
import { SourceType } from '@core/models/source-type';
import { SubscriptionTypeShortened } from '@core/models/subscription-type';
import { ICustomFormOption } from '@shared/features/custom-forms/custom-form-option.model';
import { environment } from 'src/environments/environment';
import { IContact, ITag } from 'src/app/admin/contacts/contact.model';

export const getPayload = <T>(token: string): T => JSON.parse(atob(token.split('.')[1]));

export const getFilename = (filePath: string): string => /[^/]*$/.exec(filePath)[0];

export const getFileExtension = (filename): string => {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
};

export const setCapitalLetter = (data): string => {
  const capitalLetter = data[0].toUpperCase();
  return capitalLetter + data.toLowerCase().slice(1);
};

export const isEmpty = obj => {
  for (const prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

export const downloadFile = (filename: string, file: any): void => {
  const dataType = file.type;
  const binaryData = [];
  binaryData.push(file);
  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
  if (filename) {
    downloadLink.setAttribute('download', filename);
  }
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const downloadJSON = (json): void => {
  const downloadLink = document.createElement('a');
  downloadLink.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(json));
  downloadLink.download = 'survey-results.json';
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const getCurrentTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const convertDayToDayName = (day: number): string => {
  return weekDays[(day - 1) % 7];
};

export const convertMonthToMonthName = (month: number, quarterly = 0): string => {
  return monthNames[month - 1 + 3 * quarterly];
};

export const getMonthAndDay = (day: number): any => {
  const month = Math.ceil(day / 31);
  const passMonth = month < 4 ? 0 : 1;
  const monthCheck = month % 3 == 0;
  const dayCheck = day % 31 == 0;
  const dayCheckReturn = dayCheck ? 31 : day % 31;
  const monthCheckReturn = monthCheck ? 3 : month % 3;
  return { month: passMonth ? passMonth : monthCheckReturn, day: passMonth ? passMonth : dayCheckReturn };
};

export const getDayTail = (day: number): string => {
  switch (day) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getPaginationParams = (params: IPagination = {}): IPagination =>
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  Object.fromEntries(Object.entries(params).filter(([_, v]) => v != null));

export const buildPaginationParams = (params: IPagination = {}): string => {
  return buildParamsFromObject(params);
};

export const buildParamsFromObject = (params: any = {}): string => {
  const queryParams = Object.keys(params)
    .map(key => {
      return params[key] ? key + '=' + encodeURI(params[key]) : '';
    })
    .filter(key => key !== '' && key !== undefined)
    .join('&');
  return queryParams.length > 1 ? `?organizationId={organizationId}&${queryParams}` : '';
};

export const getSubscriptionTypeShortened = (type: string): string => {
  return type ? (<any>SubscriptionTypeShortened)[type.toUpperCase().replace(/\s/g, '')] : '';
};

export const getAvoidLoaderHeaders = (): { headers: HttpHeaders } => {
  const customHeaders = new HttpHeaders({ [environment.avoidLoaderHeader]: 'true' });
  return { headers: customHeaders };
};

export const isPhone = (media: string): boolean => media === MediaQueries.PHONE;

export const isTablet = (media: string): boolean =>
  media === MediaQueries.DESKTOP || media === MediaQueries.TABLET || media === MediaQueries.TABLETLANDSCAPE;

export const isDesktopUp = (media: string): boolean => media === MediaQueries.DESKTOPBIG || media === MediaQueries.DESKTOPLARGE;

export const getInvalidChars = (): Set<string> => {
  const invalidChars = new Set<string>();

  invalidChars.add('[');
  invalidChars.add(']');
  invalidChars.add('\\');
  invalidChars.add('|');
  invalidChars.add('{');
  invalidChars.add('}');
  invalidChars.add("'");
  invalidChars.add('"');
  invalidChars.add('Dead');
  invalidChars.add('^');
  invalidChars.add('`');

  return invalidChars;
};

export const clearInvalidCharsFromText = (text: string): string => {
  const invalidChars = getInvalidChars();
  const checked = [...text].map(letter => (!invalidChars.has(letter) ? letter : ' '));
  return checked.join('').trim();
};

export const trimString = (value: string): string => {
  if (typeof value === 'string') {
    return value.trim();
  } else {
    return value;
  }
};

export const getFileNameFromHeader = (fileNameHeader: string): string => {
  const startReg = /=/;
  const quotesReg = /"/gm;
  const fileName = fileNameHeader.split('filename')[1].replace(startReg, '').replace(quotesReg, '');
  return fileName.trim();
};

export const getBooleanValue = (value: string | boolean): boolean => {
  return typeof value === 'string' ? !!+value : !!value;
};

export const decision = (conditionMethod, trueOutcome, falseOutcome) => (conditionMethod ? trueOutcome : falseOutcome);

export const numericPattern = '^[0-9]*$';
export const numericRegex = /\-?\d*\.?\d{1,2}/;
export const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const replacer = (_key, value) => {
  if (value instanceof Map) {
    return Object.fromEntries(Array.from(value.entries()));
  } else if (value instanceof Set) {
    return Array.from(value.values());
  } else {
    return value;
  }
};

export const getPolicyRoute = (policy: ICatalogPolicy): string => {
  const { id, policyOwner } = policy;
  let endpoint = 'catalog-details';
  if (policyOwner) {
    endpoint = 'edit-policy';
  }
  return `/catalog/${endpoint}/${id}`;
};

export const handleRecipients = <T extends { name: string; id: any }>(recipients: T[]): ICustomFormOption<string>[] => {
  return recipients.map((item: T) => ({
    label: item.name,
    value: item.id,
    selected: false,
  }));
};

const levelZeroSourceTypes: string[] = [SourceType.PDF.toLowerCase(), SourceType.SHAREPOINT.toLowerCase()];

export const isLevelZeroSourceType = (source: string): boolean => {
  return levelZeroSourceTypes.includes(source.toLowerCase());
};

export const addEmptyValue = (options: ICustomFormOption<string>[]): ICustomFormOption<string>[] => {
  return [{ label: 'None', value: null }, ...options];
};

export const getContactsWithStringTags = (contacts: IContact<ITag[]>[]) => {
  return contacts.map(contact => {
    const { tags } = contact;
    const tagsString = tags.map(tag => {
      return tag.name;
    });
    return {
      ...contact,
      tags: tagsString.toString(),
    };
  });
};

type CompressOptions = {
  quality: number;
  height?: number;
  width?: number;
};

export const compressImage = (file, options: CompressOptions = { quality: 1 }): Observable<any> => {
  // Get as image data
  const imageBitmap = from(createImageBitmap(file));

  return imageBitmap.pipe(
    concatMap((res: ImageBitmap) => {
      const { quality, width } = options;
      const type = file.type;
      let size;
      if (width > res.width) {
        size = { width: res.width, height: res.height };
      } else {
        size = calculateAspectRatioFit(res.width, res.height, width);
      }
      // Draw to canvas
      const canvas = document.createElement('canvas');
      canvas.width = size.width || res.width; // 658
      canvas.height = size.height || res.height; // 279
      const ctx = canvas.getContext('2d');
      if (size && width < res.width) {
        ctx.drawImage(res, 0, 0, size.width, size.height);
      } else {
        ctx.drawImage(res, 0, 0);
      }

      // Turn into Blob
      return new Observable(subscriber => {
        canvas.toBlob(
          blob => {
            const newFile = new File([blob], file.name, {
              type: blob.type,
            });
            subscriber.next(newFile);
          },
          type,
          quality
        );
      });
    })
  );
};

/**
 * Conserve aspect ratio of the original region. Useful when shrinking/enlarging
 * images to fit into a certain area.
 *
 * @param {Number} srcWidth width of source image
 * @param {Number} srcHeight height of source image
 * @param {Number} maxWidth maximum available width
 * @param {Number} maxHeight maximum available height
 * @return {Object} { width, height }
 */
export const calculateAspectRatioFit = (srcWidth, srcHeight, maxWidth) => {
  let size = { width: maxWidth, height: maxWidth }; // default square type img
  if (srcWidth > srcHeight || srcWidth + srcHeight) {
    const ratio = maxWidth / srcWidth;
    size = { width: maxWidth, height: srcHeight * ratio };
  }
  return size;
};

export const notEmpty = value => value !== undefined && value !== null && value !== '';

export const emitComponentLoaded = (window, eventName = 'loading complete'): void => {
  try {
    window.parent.postMessage(eventName, '*');
  } catch {
    return;
  }
};
