<app-modal>
  <header>{{ 'Unsubscribe from Policy' | translate }}</header>
  <main>
    <h3>{{ policy?.name }}</h3>
    <p>{{ 'Are you sure you want to unsubscribe?' | translate }}</p>
  </main>
  <footer>
    <button class="app-btn app-btn--primary" (click)="unsubscribe()">{{ 'Unsubscribe' | translate }}</button>
  </footer>
</app-modal>
