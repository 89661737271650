import { Component, Input, OnDestroy } from '@angular/core';

import { faClipboard, faEye, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ClipboardService } from 'ngx-clipboard';
import { Subscription } from 'rxjs';

import { AlertService } from '@core/alert/alert.service';
import { NavigationService } from '@core/services/navigation.service';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss'],
})
export class MyAccountComponent implements OnDestroy {
  @Input() public user;
  @Input() public encryptionKey: string;

  faPowerOff = faPowerOff;
  faClipboard = faClipboard;
  faEye = faEye;
  showEncryptionKey = false;
  subscriptions = new Subscription();

  constructor(
    private alertService: AlertService,
    private clipboardService: ClipboardService,
    private navigationService: NavigationService,
    private ngbActiveModal: NgbActiveModal,
    private translateService: TranslateService,
    private userService: UserService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  copyEncryptionKey = () => {
    this.clipboardService.copyFromContent(this.encryptionKey);
  };

  dismiss = () => {
    this.ngbActiveModal.close();
  };

  logout = (): void => this.navigationService.logout();

  resetPassword = () => {
    this.subscriptions.add(
      this.userService.resetPassword().subscribe(response => {
        const message = this.translateService.instant(response);
        this.alertService.success(message);
      })
    );
  };
}
