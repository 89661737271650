import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';

import { switchMap, skipWhile } from 'rxjs/operators';
import { combineLatest, Observable, of } from 'rxjs';

import { AuthService } from '@core/auth/auth.service';
import { PlatformService } from '@core/services/platform.service';

@Injectable({
  providedIn: 'root',
})
export class PlatformAdminGuard {
  constructor(private authService: AuthService, private platformService: PlatformService, private router: Router) {}

  canActivate(
    childRoute: ActivatedRouteSnapshot, // eslint-disable-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot // eslint-disable-line @typescript-eslint/no-unused-vars
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.platformUserCheck();
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot, // eslint-disable-line @typescript-eslint/no-unused-vars
    state: RouterStateSnapshot // eslint-disable-line @typescript-eslint/no-unused-vars
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.platformUserCheck();
  }

  platformUserCheck = (): Observable<boolean> => {
    const actAs$ = this.authService.userActAs$;
    const platformId$ = this.platformService.getPlatformId();
    return combineLatest([actAs$, platformId$]).pipe(
      skipWhile(([actAsArray]) => {
        return !actAsArray === null || actAsArray?.length === 0;
      }),
      switchMap(([[actAs], platformId]) => {
        if (actAs.toLowerCase().includes(platformId)) {
          this.router.navigate(['/admin/users']);
          return of(false);
        }
        return of(true);
      })
    );
  };
}
