import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';

import * as moment from 'moment';
import { Observable, BehaviorSubject, combineLatest, zip, EMPTY, of } from 'rxjs';
import { catchError, concatMap, map, share } from 'rxjs/operators';

import { ICatalogPolicy } from './catalogPolicy.model';
import { AuthService } from '@core/auth/auth.service';
import { IDataSource } from '@core/models/data-source.model';
import { IDetailPageContributionRow } from '@core/models/detail-page-contribution-row.model';
import { ISubscriptionLicense } from '@core/models/licenses-page-data.model';
import { INotification } from '@core/models/notification.model';
import { IPagination } from '@core/models/pagination-params';
import { IPaginationResponse } from '@core/models/pagination-response.model';
import { IPolicy } from '@core/models/policy.model';
import { ISharepointDownload } from '@core/models/sharepoint-download.model';
import { SubscriptionState } from '@core/models/subscription-state';
import { IUserInvitation } from '@core/models/user-invitation.model';
import { IUser } from '@core/models/user.model';
import { PolicyService } from '@shared/services/policy.service';
import { SubscriptionsService } from '@shared/services/subscriptions.service';
import { DataSourcesService } from '@shared/services/source-data.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CatalogService {
  private pendingRequests = new BehaviorSubject<INotification[]>(null);
  pendingRequests$ = this.pendingRequests.asObservable();

  constructor(
    private authService: AuthService,
    private policyService: PolicyService,
    private datasourceService: DataSourcesService,
    private http: HttpClient,
    private subscriptionsService: SubscriptionsService
  ) {}

  policyChange = (): Observable<string> => this.policyService.policyChange$;

  addDays = (date: string, num: number, frequency: string): string => {
    const newDate = moment(new Date(date));
    switch (frequency.toLowerCase()) {
      case 'daily':
        newDate.add(num, 'd');
        break;
      case 'weekly':
        newDate.add(num, 'w');
        break;
      case 'monthly':
        newDate.add(num, 'M');
        break;
      case 'quarterly':
        newDate.add(num, 'Q');
        break;
      case 'yearly':
        newDate.add(num, 'y');
        break;
    }
    return newDate.format();
  };

  policySubscribe = (policy: ICatalogPolicy): Observable<string> => {
    const { id } = policy;
    const url = environment.SUBSCRIBEPOLICY.replace('{policyId}', id);
    return this.http.post(url, {}, { responseType: 'text' });
  };

  policyUnsubscribe = (policy: ICatalogPolicy): Observable<string> => {
    const { id } = policy;
    const url = environment.UNSUBSCRIBEPOLICY.replace('{policyId}', id);
    return this.http.post(url, {}, { responseType: 'text' });
  };

  buildCatalogPolicy = (actAs: string[], policy: IPolicy): ICatalogPolicy => {
    const { publisher } = policy;

    const policyOwner = actAs.find(item => publisher === item);
    return {
      ...policy,
      policyOwner: policyOwner !== undefined,
    };
  };

  private calculateDifference = (frequency: string, startDate: string): number => {
    const start = moment(startDate);
    const end = moment();
    let result = 0;
    switch (frequency.toLowerCase()) {
      case 'daily':
        result = Math.round(Math.abs(moment.duration(start.diff(end)).asDays()));
        break;
      case 'weekly':
        result = Math.round(Math.abs(moment.duration(start.diff(end)).asWeeks()));
        break;
      case 'monthly':
        result = Math.round(Math.abs(moment.duration(start.diff(end)).asMonths()));
        break;
      case 'quarterly':
        result = Math.floor(end.diff(start, 'months') / 3);
        break;
      case 'yearly':
        result = Math.round(Math.abs(moment.duration(start.diff(end)).asYears()));
        break;
    }

    return result;
  };

  decryptFile = (file: File): Observable<HttpResponse<Blob>> => {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<Blob>(environment.DECRYPT, formData, { observe: 'response', responseType: 'blob' as 'json' });
  };

  downloadFilteredContribution = (policyId: string, params: IDetailPageContributionRow): Observable<HttpResponse<Blob>> => {
    let url = '';
    if (params.siteId != null) {
      // SharePoint/OneDrive
      url = environment.DOWNLOADFILEPOLICIESSHAREPOINT.replace('{policyId}', policyId);
      const sharepointDownload: ISharepointDownload = {
        siteId: params.siteId,
        driveItemId: params.driveItemId,
      };
      return this.http.post<Blob>(url, sharepointDownload, { observe: 'response', responseType: 'blob' as 'json' });
    }

    // Others
    url = params.contributionId
      ? environment.DOWNLOADFILEPOLICIES.replace('{fileName}', params.file)
          .replace('{policyId}', policyId)
          .replace('{contributionId}', params.contributionId)
      : environment.DOWNLOADREALTIMEFILEPOLICIES.replace('{fileName}', params.file).replace('{policyId}', policyId);
    return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' });
  };

  downloadLicense = (policyId: string): Observable<any> => {
    const url = environment.DOWNLOADLICENSEPOLICIES.replace('{policyId}', policyId);
    return this.http.get(url, { responseType: 'arraybuffer' });
  };

  getPolicy = (id: string): Observable<IPolicy> => this.policyService.getPolicy(id);

  getPolicies = (params?: IPagination, options = {}): Observable<IPaginationResponse<IPolicy>> => {
    return this.policyService.getPolicies(params, options);
  };

  getContributors = (): Observable<IUserInvitation[]> => {
    return this.http.get<IUserInvitation[]>(environment.ORGANIZATIONSADMINS).pipe(share());
  };

  getDatasource = (id: string): Observable<IDataSource> => this.datasourceService.getDataSource(id);

  getDetailPreviewData = (id: string): Observable<any> => {
    const url = environment.PREVIEWDATA.replace('{policyId}', id);
    return this.http.get<any>(url);
  };

  getLicenseDetailPreviewData = (policyId: string, subscriberId: string): Observable<any> => {
    return this.subscriptionsService.getContractPreview(policyId, subscriberId);
  };

  getHistoricalData = (startDate: string, subscription: string, amount: number, frequency: string) => {
    let historicalData = [];
    const amountDays = this.calculateDifference(frequency, startDate);

    for (let i = 0; i <= amountDays; i++) {
      const newDate = this.addDays(startDate, i, frequency);
      historicalData = [...historicalData, { date: moment(newDate).format('DD MMMM YYYY'), subscription, amount }];
    }
    return historicalData;
  };

  getCatalogPolicy = (id: string): Observable<ICatalogPolicy> => {
    const actAs$ = this.authService.userProfile$;
    const policy$ = this.getPolicy(id);
    return zip(actAs$, policy$).pipe(
      map(([user, policy]: [IUser, IPolicy]) => {
        const actAs = this.authService.getUserActAs(user);
        return this.buildCatalogPolicy(actAs, policy);
      })
    );
  };

  getCatalogPolicies = (params?: IPagination): Observable<IPaginationResponse<ICatalogPolicy>> => {
    const actAs$ = this.authService.userActAs$;
    const policies$ = this.getPolicies(params);
    const result$ = combineLatest([actAs$, policies$]);
    return result$.pipe(
      map(([actAs = [], policiesResponse = { list: [], totalPages: 0 }]: [string[], IPaginationResponse<IPolicy>]) => {
        const { list, totalPages } = policiesResponse;
        return { list: list.map((policy: IPolicy) => this.buildCatalogPolicy(actAs, policy)), totalPages };
      })
    );
  };

  getUnencryptedOneDriveFile = (policyId: string, sharepointDownload: ISharepointDownload): Observable<HttpResponse<Blob>> => {
    const url = environment.DOWNLOADUNENCRYPTEDFILESHAREPOINT.replace('{policyId}', policyId);
    return this.http.post<Blob>(url, sharepointDownload, { observe: 'response', responseType: 'blob' as 'json' });
  };

  getUnencryptedFile = (policyId: string, contributionId: string): Observable<HttpResponse<Blob>> => {
    const url = contributionId
      ? environment.DOWNLOADUNENCRYPTEDFILE.replace('{policyId}', policyId).replace('{contributionId}', contributionId)
      : environment.DOWNLOADREALTIMEUNENCRYPTEDFILE.replace('{policyId}', policyId);
    return this.http.get<Blob>(url, { observe: 'response', responseType: 'blob' as 'json' });
  };

  rejectSubscription = (id: string, subscriber: string): Observable<any> => {
    const url = environment.REJECTSUBSCRIPTION.replace('{policyId}', id).replace('{subscriber}', subscriber);
    return this.http.post(url, {}, { responseType: 'text' });
  };

  getPolicyDetailPage = (
    policyId: string
  ): Observable<{ policy: IPolicy } | { policy: IPolicy; subscriptionContract: ISubscriptionLicense }> => {
    const actAs = this.authService.userActAs$;
    const policy = this.getCatalogPolicy(policyId).pipe(
      catchError(() => {
        return EMPTY;
      })
    );
    return zip(policy, actAs).pipe(
      concatMap(([catalogPolicy, userActAs]) => {
        const { id, subscriptionState } = catalogPolicy;
        const catalogPolicy$ = of(catalogPolicy);
        if (subscriptionState === SubscriptionState.SUBSCRIBED) {
          return zip(catalogPolicy$, this.subscriptionsService.getSubscriptionContract(id, userActAs[0]));
        }
        return zip(catalogPolicy$);
      }),
      map(response => {
        const [policyResponse, subscriptionContract] = response;
        return { policy: policyResponse, subscriptionContract };
      })
    );
  };

  userProfile = (): Observable<IUser> => {
    return this.authService.userProfile$;
  };
}
