import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, of, zip } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { skipWhile, tap, concatMap, shareReplay, switchMap } from 'rxjs/operators';

import { AuthService } from '@core/auth/auth.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  private platformId$ = new BehaviorSubject(null);

  constructor(private authService: AuthService, private httpClient: HttpClient) {}

  getPlatformId = (): Observable<string> => this.platformId$.pipe(skipWhile(platformId => platformId === null));

  setPlatformId = (): Observable<string> => {
    const headers = new HttpHeaders({ [environment.avoidOrgId]: 'true' });
    return this.httpClient.get(environment.PLATFORMUSERID, { responseType: 'text', headers }).pipe(
      tap(id => {
        this.platformId$.next(id);
      })
    );
  };

  initPlatformId() {
    return () =>
      new Observable(subscriber => {
        const setPlatformId$ = this.setPlatformId();
        this.authService.isAuthenticated$
          .pipe(
            concatMap(isLogged => {
              return isLogged ? setPlatformId$ : of(false);
            })
          )
          .subscribe(() => {
            subscriber.complete();
          });
      });
  }

  isPlatformUser = (): Observable<boolean> => {
    const actAs$ = this.authService.userActAs$;
    const platformId$ = this.getPlatformId();
    return zip(actAs$, platformId$).pipe(
      skipWhile(([actAsArray]) => !actAsArray === null || actAsArray?.length === 0),
      switchMap(([[actAs], platformId]) => of(actAs.includes(platformId))),
      shareReplay()
    );
  };
}
