import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { IAlert, AlertType } from './alert.model';

@Injectable({ providedIn: 'root' })
export class AlertService {
  private subject = new BehaviorSubject<IAlert | null>(null);
  private defaultId = 'default-alert';
  private defaultConf = {
    autoClose: true,
  };

  // enable subscribing to alerts observable
  onAlert(id = this.defaultId): Observable<IAlert> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  // convenience methods
  success = (message: string, options?: any) => {
    this.alert(new IAlert({ ...this.defaultConf, ...options, type: AlertType.Success, message }));
  };

  error = (message: string, options?: any) => {
    this.alert(new IAlert({ ...this.defaultConf, ...options, type: AlertType.Error, message }));
  };

  info = (message: string, options?: any) => {
    this.alert(new IAlert({ ...this.defaultConf, ...options, type: AlertType.Info, message }));
  };

  warn = (message: string, options?: any) => {
    this.alert(new IAlert({ ...this.defaultConf, ...options, type: AlertType.Warning, message }));
  };

  // main alert method
  alert(alert: IAlert) {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  // clear alerts
  clear(id = this.defaultId) {
    this.subject.next(new IAlert({ id }));
  }
}
