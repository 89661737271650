<app-modal>
  <header>{{ labels.title }}</header>
  <main>
    <p [innerHTML]="labels.description | NoSanitize"></p>
  </main>
  <footer>
    <ng-container *ngIf="labels.cancel">
      <button type="button" class="app-btn app-btn--secondary" (click)="close()">
        {{ labels.cancel }}
      </button>
    </ng-container>
    <button type="button" class="app-btn app-btn--primary app-submit" (click)="confirm()">
      {{ labels.confirm }}
    </button>
  </footer>
</app-modal>
